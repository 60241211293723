import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PrintIcon from "@mui/icons-material/Print";
import KeyIcon from "@mui/icons-material/Key";
import hotelPolicyImg from "../../assets/images/consent-form.png";
import checkInSlip from "../../assets/images/checkin-slip.png";
import feedbackForm from "../../assets/images/feedback-form.png";

import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";

import theme from "../../assets/theme/theme";
import { store } from "../../redux/store";
import CheckinSlipPrintModal from "./CheckinSlipPrintModal";
import ConsentFormPrintModal from "./ConsentFormPrintModal";
import {
  BookingResult,
  BookingStatus,
  RoomBooking,
} from "../../pages/booking/interface";
import FeedbackFormPage from "../../pages/feedback/FeedbackFormPage";
import FeedbackFormPrintModal from "./FeedbackFormPrintModal";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  border: 2,
  borderColor: theme.palette.primary.main,
};

const ROOM_BOOKING_STATUS = [
  { value: "Check In", id: "check-out" },
  { value: "Check Out", id: "check-out" },
  { value: "Overstayed", id: "check-out" },
  { value: "Booked", id: "Booked" },
  { value: "Confirm Booking", id: "check-in" },
  { value: "No Show", id: "check-in" },
  { value: "Transfer From", id: "check-in" },
  { value: "Reservation", id: "check-in" },
];

type Props = {
  openDocumentModal: boolean;
  handleDocumentModalClose: () => void;
  bookingStatusObj: BookingStatus;
  bookingData: BookingResult;
};

///This modal is to show document.
function DocumentModal({
  openDocumentModal,
  handleDocumentModalClose,
  bookingStatusObj,
  bookingData,
}: Props) {
  const token = store.getState().user.accessToken;
  const accountId = useSelector((state: RootState) => state.user.accountId);
  const [activePIN, setActivePIN] = useState<string>("");
  const [bookingStatus, setBookingStatus] = useState<string>("");
  const [bookingStatusError, setBookingStatusError] = useState<string>("");

  const [showCheckinSlip, setShowCheckinSlip] = useState<boolean>(false);
  const [showConsentForm, setShowConsentForm] = useState<boolean>(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>(false);

  useEffect(() => {
    const findBookingStatus = () => {
      let status = ROOM_BOOKING_STATUS.find(
        (item) => item.value === bookingStatusObj.bookingStatus
      );
      if (!status) {
        status = ROOM_BOOKING_STATUS.find(item => bookingStatusObj.bookingStatus.includes(item.value));
      }
      
      if (status) {
        setBookingStatus(status?.id);
      } else {
        Notification.failed("No Booking Status Matched");
      }
    };
    findBookingStatus();
  }, [bookingStatusObj.bookingStatus]);

  const validateBookingStatus = () => {
    let isValid = true;
    if (!bookingStatus) {
      setBookingStatusError("Please select status");
      isValid = false;
    } else {
      setBookingStatusError("");
    }
    return isValid;
  };

  const handleGetPIN = () => {
    if (validateBookingStatus()) {
      const apiUrl = `/bookings/v1/consent/get-pin/?accountId=${accountId}&process=${bookingStatus}&bookingId=${bookingData.bookingId}`;

      axiosInstance
        .get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setActivePIN(response.data.pin);
          //setFieldValue("bookingInfo.pin", response.data.pin);
          Notification.success("PIN Successfully Retrieved");
        });
    }
  };

  return (
    <>
      {/* -------------------------------------------------------------------------- */
      /*                   Modal to show documents                              */
      /* -------------------------------------------------------------------------- */}
      <Modal open={openDocumentModal} onClose={handleDocumentModalClose}>
        <Box sx={modalStyle} bgcolor={theme.palette.background.default}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            sx={{ background: "#141414" }}
            overflow={"auto"}
          >
            {showConsentForm && (
              <ConsentFormPrintModal
                bookingId={bookingData.bookingId}
                guestId={bookingData.customerStaying}
                handleDocumentModalClose={handleDocumentModalClose}
                handleBack={() => setShowConsentForm(false)}
              />
            )}
            {showCheckinSlip && (
              <CheckinSlipPrintModal
                handleDocumentModalClose={handleDocumentModalClose}
                handleBack={() => setShowCheckinSlip(false)}
                bookingData={bookingData}
              />
            )}
            {showFeedbackForm && (
              <FeedbackFormPage
                handleDocumentModalClose={handleDocumentModalClose}
                handleBack={() => setShowFeedbackForm(false)}
                bookingId={bookingData.bookingId}
              />
            )}
            {!showCheckinSlip && !showConsentForm && !showFeedbackForm && (
              <Grid container spacing={0} sx={{ p: 0, width: "100%" }}>
                <Grid item xs={11}>
                  <Stack
                    display={"flex"}
                    sx={{
                      p: 2,
                      border: 0,
                      borderColor: "#232323",
                      height: "50px",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h2" color={theme.palette.primary.main}>
                      Documents
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={1}>
                  <Stack
                    display={"flex"}
                    sx={{
                      p: 1,
                      border: 0,
                      borderColor: theme.palette.primary.main,
                      height: "50px",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      onClick={handleDocumentModalClose}
                      sx={{ padding: 0 }}
                    >
                      <HighlightOffIcon
                        fontSize="large"
                        sx={{ color: "#ff4d56" }}
                      />
                    </IconButton>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    orientation="horizontal"
                    variant="fullWidth"
                    flexItem
                    sx={{
                      borderBottomWidth: 2.5,
                      borderColor: theme.palette.primary.main,
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Stack
                    display={"flex"}
                    sx={{
                      p: 3,
                      border: 0,
                      borderColor: theme.palette.primary.main,
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={hotelPolicyImg}
                      alt="consent-form"
                      width={"100%"}
                    />
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography variant={"h4"} fontWeight={500}>
                        Hotel Policy
                      </Typography>
                      <Stack
                        direction={"row"}
                        justifyContent={"end"}
                        alignItems={"center"}
                      >
                        {activePIN ? (
                          <Box
                            display={"flex"}
                            border={1}
                            borderRadius={1}
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderColor={theme.palette.secondary.main}
                            bgcolor={theme.palette.background.default}
                            sx={{ marginX: "10%", paddingX: 1 }}
                          >
                            <KeyIcon
                              sx={{
                                color: theme.palette.primary.main,
                                marginRight: 1,
                              }}
                            />
                            {activePIN
                              .split("")
                              .map((value, index) =>
                                index !== activePIN.length - 1 ? (
                                  <Typography variant="h4">
                                    {" "}
                                    {value}.
                                  </Typography>
                                ) : (
                                  <Typography variant="h4">{value}</Typography>
                                )
                              )}
                          </Box>
                        ) : (
                          <>
                            <TextField
                              size="small"
                              value={bookingStatus}
                              select
                              onChange={(e) => setBookingStatus(e.target.value)}
                              sx={{
                                width: "150px",
                                ".MuiInputBase-input": {
                                  border: "none",
                                  paddingY: "5px",
                                  backgroundColor: "white",
                                  color: "black",
                                  "&:focus": {
                                    borderColor: "black",
                                  },
                                },
                              }}
                              error={!!bookingStatusError}
                              helperText={bookingStatusError}
                              disabled
                            >
                              <MenuItem value={"check-in"}>Check In</MenuItem>
                              <MenuItem value={"check-out"}>Check Out</MenuItem>
                            </TextField>
                            <IconButton onClick={handleGetPIN}>
                              <KeyIcon
                                sx={{
                                  color: theme.palette.primary.main,
                                  border: 0.5,
                                  borderRadius: 1,
                                  p: 0.2,
                                }}
                              />
                            </IconButton>
                          </>
                        )}
                        <IconButton onClick={() => setShowConsentForm(true)}>
                          <PrintIcon
                            sx={{
                              color: "black",
                              background: theme.palette.primary.main,
                              border: 0.5,
                              borderRadius: 1,
                              p: 0.2,
                            }}
                          />
                        </IconButton>
                      </Stack>
                    </Stack>

                    <Typography>11 jan 2023 12:30</Typography>
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack
                    display={"flex"}
                    sx={{
                      p: 3,
                      border: 0,
                      borderColor: theme.palette.primary.main,
                      justifyContent: "center",
                    }}
                  >
                    <img src={checkInSlip} alt="checkin-slip" width={"100%"} />
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography variant={"h4"} fontWeight={500}>
                        Check-In Slip
                      </Typography>
                      <IconButton onClick={() => setShowCheckinSlip(true)}>
                        <PrintIcon
                          sx={{
                            color: "black",
                            background: theme.palette.primary.main,
                            border: 0.5,
                            borderRadius: 1,
                            p: 0.2,
                          }}
                        />
                      </IconButton>
                    </Stack>
                    <Typography>11 jan 2023 12:30</Typography>
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack
                    display={"flex"}
                    sx={{
                      p: 3,
                      border: 0,
                      borderColor: theme.palette.primary.main,
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={feedbackForm}
                      alt="feedback-form"
                      width={"100%"}
                    />

                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography variant={"h4"} fontWeight={500}>
                        Feedback Form
                      </Typography>
                      <IconButton onClick={() => setShowFeedbackForm(true)}>
                        <PrintIcon
                          sx={{
                            color: "black",
                            background: theme.palette.primary.main,
                            border: 0.5,
                            borderRadius: 1,
                            p: 0.2,
                          }}
                        />
                      </IconButton>
                    </Stack>
                    <Typography>11 jan 2023 12:30</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    sx={{
                      p: 2,
                      alignItems: "end",
                      height: "80px",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleDocumentModalClose}
                    >
                      Close
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default DocumentModal;
