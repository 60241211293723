import React, { useEffect, useState, useMemo } from "react";
import theme from "../../assets/theme/theme";

import {
  Box,
  Button,
  CircularProgress,
  darken,
  Grid,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import {
  ArrowDropDownIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import XLSX from "sheetjs-style";

import { store } from "../../redux/store";
import axiosInstance from "../../constants/axiosConfig";
import Notification from "../../utils/notificationConfig";
import CustomTable from "../../components/global/table/CustomTable";
import Membership from "./Membership";

import { ReactComponent as ExcelIcon } from "../../assets/images/icons8-excel.svg";
export interface IMembershipProgress {
  customerId: string;
  customerName: string;
  milestoneProgress: number;
  retentionCount: number;
  claimedPromoCount: number;
  latestAppliedDate: string;
}

/* -------------------------------------------------------------------------- */
/*                               main component                               */
/* -------------------------------------------------------------------------- */

export default function Guest() {
  /* -------------------------------- useStates ------------------------------- */

  const [membershipProgress, setMemberProgress] = useState<
    IMembershipProgress[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 8,
  });
  const [tabStepperValue, setTabStepperValue] = useState(0);

  const currentDate = new Date();

  // Start of the month
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  // End of the month
  const endOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const [startDate, setStartDate] = useState<Date>(startOfMonth);
  const [endDate, setEndDate] = useState<Date>(endOfMonth);

  const [isGeneratingExcel, setIsGeneratingExcel] = useState<boolean>(false);

  /* -------------------------- state function handle ------------------------- */

  const handleStepperChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabStepperValue(newValue);
  };

  const handleStartDateChange = (startDate: Date) => {
    setStartDate(startDate);
  };

  const handleEndDateChange = (endDate: Date) => {
    endDate.setHours(23, 59, 59, 999);
    setEndDate(endDate);
  };

  /* ------------------------------- useEffects ------------------------------- */

  useEffect(() => {
    const fetchMembershipProgress = async () => {
      const token = store.getState().user.accessToken;

      setIsLoading(true);

      axiosInstance
        .get(`/guests/membership-progress`, {
          params: {
            startDateTime: startDate ? startDate : "",
            endDateTime: endDate ? endDate : "",
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => {
          setMemberProgress(
            res.data.data.map((data: any) => ({
              ...data,
              milestoneProgress: parseFloat(data.milestoneProgress),
            }))
          );
        })
        .catch((res) => Notification.failed("Error Fetching Guest Data"))
        .finally(() => setIsLoading(false));
    };
    fetchMembershipProgress();
  }, [startDate, endDate]);

  function isValidDate(dateString: string) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  /* ------------------------------- Guest Data Column ------------------------------- */

  const membershipProgressColumn = useMemo<
    MRT_ColumnDef<IMembershipProgress>[]
  >(
    () => [
      {
        header: "Customer Name",
        accessorKey: "customerName",
      },
      {
        header: "Milestone",
        Cell: ({ row }) => (
          <LinearProgress
            variant="determinate"
            value={row.original.milestoneProgress}
            color="primary"
            sx={{
              backgroundColor: darken(theme.palette.primary.main, 0.7),
              "& .MuiLinearProgress-bar": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          />
        ),
      },
      {
        header: "Retention Count",
        accessorKey: "retentionCount",
      },
      {
        header: "Claimed Promo",
        accessorKey: "claimedPromoCount",
      },
      {
        header: "Latest Applied",
        Cell: ({ row }) => {
          return row.original.latestAppliedDate === "-" ||
            !isValidDate(row.original.latestAppliedDate)
            ? row.original.latestAppliedDate
            : format(
                new Date(row.original.latestAppliedDate),
                "dd/MM/yyyy HH:mm"
              );
        },
      },
    ],
    []
  );

  const handleXLSXDownload = async () => {
    setIsGeneratingExcel(true);
    try {
      const dataToExport = membershipProgress.map((progress) => ({
        ...progress,
        latestAppliedDate: isValidDate(progress.latestAppliedDate)
          ? format(new Date(progress.latestAppliedDate), "dd/MM/yyyy HH:mm")
          : "-",
      }));

      const reportNameHeader = "Report Type: Membership Progress";
      const startAndEndDate = `Report Date from: ${format(
        startDate,
        "dd MMMM yyyy"
      )}        to: ${format(endDate, "dd MMMM yyyy")}`;
      const dateGenerated = `Date Generated: ${format(
        new Date(),
        "dd MMMM yyyy hh:mm"
      )}`;
      let Heading = [[reportNameHeader], [startAndEndDate], [dateGenerated]];
      // const ws = XLSX.utils.json_to_sheet(dataToExport);
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, Heading);

      XLSX.utils.sheet_add_json(ws, dataToExport, {
        origin: "A5",
      });
      const borderStyle = {
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
      ws["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
      ];

      Object.keys(ws).forEach((cell) => {
        if (cell !== "!ref") {
          ws[cell].s = borderStyle;
        }
      });
      ws["A1"].s = {
        font: {
          sz: 18,
          bold: true,
        },
      };
      ws["A2"].s = {
        font: {
          sz: 18,
          bold: true,
        },
      };
      ws["A3"].s = {
        font: {
          sz: 18,
          bold: true,
        },
      };

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
      XLSX.writeFile(
        wb,
        `${format(currentDate, "ddMMyyyy")}_Membership_Progress.xlsx`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsGeneratingExcel(false);
    }
  };

  return (
    <Stack width={"100%"} alignItems={"center"}>
      <Tabs
        value={tabStepperValue}
        onChange={handleStepperChange}
        sx={{
          marginBottom: 5,
          "& .MuiTab-root": {
            minWidth: "30.4vw",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "1.3rem",
            "&.Mui-selected": {
              color: theme.palette.primary.main,
            },
            "&:not(.Mui-selected)": {
              color: "white",
              borderBottom: "1px solid #0a443c",
            },
          },
        }}
      >
        <Tab value={0} label={"Membership Progress"} />
        <Tab value={1} label={"Individual Membership"} />
      </Tabs>
      {tabStepperValue === 0 ? (
        <Grid
          container
          width={"80%"}
          height={"100%"}
          border={2}
          borderColor={theme.palette.primary.main}
        >
          <Grid item md={12} sm={12} xs={12}>
            <Stack
              direction={"row"}
              width={"100%"}
              height={"4.5rem"}
              justifyContent={"space-between"}
              alignItems={"center"}
              borderBottom={2}
              paddingLeft={2}
              borderColor={theme.palette.primary.main}
              sx={{ backgroundColor: theme.palette.background.default }}
            >
              <Typography variant={"h3"} color={theme.palette.primary.main}>
                Membership Progress ({membershipProgress.length})
              </Typography>
              <Box display={"flex"} alignItems={"center"}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(e) => handleStartDateChange(e as Date)}
                      format="dd MMM yyyy"
                      slots={{
                        openPickerIcon: ArrowDropDownIcon,
                      }}
                      slotProps={{
                        layout: {
                          sx: {
                            ".MuiDateCalendar-root": {
                              color: theme.palette.primary.main,
                              borderRadius: 2,
                              borderWidth: 1,
                              borderColor: theme.palette.primary.main,
                              border: "1px solid",
                              backgroundColor: theme.palette.background.paper,
                            },
                            ".MuiButtonBase-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiTypography-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersCalendarHeader-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersMonth-monthButton": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersCalendarHeader-label": {
                              color: theme.palette.primary.main,
                            },
                            ".Mui-selected": {
                              color: "black",
                            },
                            ".MuiPickersYear-yearButton": {
                              color: theme.palette.primary.main,
                            },
                          },
                        },
                        textField: {
                          variant: "filled",
                          inputProps: {
                            style: {
                              color: "white",
                              borderRadius: 1,
                              border: 2,
                            },
                          },
                          sx: {
                            width: "150px",
                            backgroundColor: "#232323",
                            border: "1px solid #148978",
                            borderRadius: 1,
                          },
                        },
                        openPickerButton: {
                          sx: {
                            color: "white",
                            marginTop: "1.8px",
                            zIndex: 1,
                          },
                        },
                        openPickerIcon: {
                          sx: {
                            width: "1em",
                            height: "1em",
                            zIndex: 1,
                          },
                        },
                      }}
                    />
                    <Typography variant="h3" marginX={1}>
                      -
                    </Typography>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(e) => handleEndDateChange(e as Date)}
                      format="dd MMM yyyy"
                      slots={{
                        openPickerIcon: ArrowDropDownIcon,
                      }}
                      slotProps={{
                        layout: {
                          sx: {
                            ".MuiDateCalendar-root": {
                              color: theme.palette.primary.main,
                              borderRadius: 2,
                              borderWidth: 1,
                              borderColor: theme.palette.primary.main,
                              border: "1px solid",
                              backgroundColor: theme.palette.background.paper,
                            },
                            ".MuiButtonBase-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiTypography-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersCalendarHeader-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersMonth-monthButton": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersCalendarHeader-label": {
                              color: theme.palette.primary.main,
                            },
                            ".Mui-selected": {
                              color: "black",
                            },
                            ".MuiPickersYear-yearButton": {
                              color: theme.palette.primary.main,
                            },
                          },
                        },
                        textField: {
                          variant: "filled",
                          inputProps: {
                            style: {
                              color: "white",
                              borderRadius: 1,
                              border: 2,
                            },
                          },
                          sx: {
                            width: "150px",
                            backgroundColor: "#232323",
                            border: "1px solid #148978",
                            borderRadius: 1,
                          },
                        },
                        openPickerButton: {
                          sx: {
                            color: "white",
                            marginTop: "1.8px",
                            zIndex: 1,
                          },
                        },
                        openPickerIcon: {
                          sx: {
                            width: "1em",
                            height: "1em",
                            zIndex: 1,
                          },
                        },
                      }}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    onClick={handleXLSXDownload}
                    sx={{ borderRadius: 1, marginX: 2 }}
                  >
                    {isGeneratingExcel ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      <ExcelIcon width={24} height={24} />
                    )}
                  </Button>
                </LocalizationProvider>
              </Box>
            </Stack>
            <Stack direction={"column"}>
              <CustomTable
                columns={membershipProgressColumn}
                data={membershipProgress}
                isLoading={isLoading}
                handlePaginationChange={setPagination}
                pagination={pagination}
                rowCount={membershipProgress.length}
                oddEvenBackground={true}
              />
            </Stack>
          </Grid>
        </Grid>
      ) : tabStepperValue === 1 ? (
        <Membership />
      ) : null}
    </Stack>
  );
}
