import {
  Backdrop,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import theme from "../../assets/theme/theme";
import axios, { AxiosResponse } from "axios";
import BASE_API from "../../constants/api";
import { store } from "../../redux/store";
import { IBooking, IBookingDraft } from "../../models/bookings/Booking";
import { FormikErrors } from "formik";
import Notification from "../../utils/notificationConfig";
import { IExistingCustomerRow } from "../../models/guests/GuestsInterface";

/**
 * referchCount is used to signal this component to refetch the data from the server
 */
type Props = {
  isExistingGuestTableOpen: boolean;
  handleSetExistingGuestTableToClose: () => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<IBooking>>;
  activeIndex: number;
  roomBookings: IBookingDraft[];
  refetchCount: number;
  hanldeNextButtonOnclick: () => void;
};

interface IAxiosExistingCustomerRowResponse {
  customerId: string;
  idNo: string;
  firstname: string;
  lastname: string;
  member: {
    memberId: string;
    memberTier: string;
    memberCondition: string;
    isArchive: boolean;
  };
  phoneNumber: string;
  email: string;
  country: {
    countryName: string;
  };
  rententionCount: number;
}

const ExisitingCustomerTableModal = React.forwardRef(
  (
    {
      isExistingGuestTableOpen,
      handleSetExistingGuestTableToClose,
      setFieldValue,
      activeIndex,
      roomBookings,
      hanldeNextButtonOnclick,
      refetchCount,
    }: Props,
    hasGottenCustomerStayingIdRef: React.ForwardedRef<boolean>
  ) => {
    const [pagination, setPagination] = useState<{
      pageIndex: number;
      pageSize: number;
    }>({ pageIndex: 0, pageSize: 7 });

    const [existingCustomerRows, setExistingCustomerRows] = useState<
      IExistingCustomerRow[]
    >([]);

    const [searchValue, setSearchValue] = useState<string>("");

    const handleExistingCustomerRowsOnChange = (
      existingCustomerRows: IExistingCustomerRow[]
    ) => {
      setExistingCustomerRows(existingCustomerRows);
    };

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchExistingCustomerRows = async () => {
      const token = store.getState().user.accessToken;

      setIsLoading(true);

      axios
        .get(`${BASE_API}/guests/search`, {
          params: {
            value: searchValue,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          ({ data }: AxiosResponse<IAxiosExistingCustomerRowResponse[]>) => {
            const existingCustomerRows = data.map((row) => {
              return {
                customerId: row.customerId,
                fullName: row.firstname + " " + row.lastname,
                icNo: row.idNo,
                memberId: row.member.memberId,
                memberTier: row.member.memberTier,
                memberCondition: row.member.memberCondition,
                countryCode: row.country.countryName,
                isEmptyRow: false,
                phone: row.phoneNumber,
                email: row.email,
                rententionCount: row.rententionCount,
              };
            });

            setExistingCustomerRows([...existingCustomerRows]);
          }
        )
        .finally(() => setIsLoading(false));
    };

    useEffect(() => {
      const handler = setTimeout(() => {
        if (searchValue) {
          fetchExistingCustomerRows();
        }
      }, 1000); // 3000 milliseconds = 3 seconds
      return () => {
        clearTimeout(handler);
      };
    }, [refetchCount, searchValue]);
    const columns = useMemo<MRT_ColumnDef<IExistingCustomerRow>[]>(
      () => [
        {
          accessorKey: "customerId",
          header: "Customer ID",
          size: 250,
        },
        {
          accessorKey: "fullName",
          header: "Name",
          size: 150,
          filterVariant: "text",
        },
        {
          accessorKey: "icNo",
          header: "IC No./Passport No.",
          size: 150,
        },
        {
          header: "Action",
          enableColumnFilter: false,
          Header: () => (
            <Typography
              paddingX={"8px"}
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
              }}
            >
              {"Action"}
            </Typography>
          ),
          accessorKey: "isEmptyRow",
          size: 100,
          Cell: ({ row }) =>
            row.original.isEmptyRow ? (
              <Button
                disabled
                sx={{
                  opacity: 0,
                }}
              >
                .
              </Button>
            ) : (
              <Button
                onClick={() => {
                  if (
                    hasGottenCustomerStayingIdRef &&
                    typeof hasGottenCustomerStayingIdRef !== "function" &&
                    !hasGottenCustomerStayingIdRef.current
                  ) {
                    setFieldValue(
                      "bookingInfo.customerStayingId",
                      row.original.customerId
                    );
                    setFieldValue(
                      "bookingInfo.customerStayingName",
                      row.original.fullName
                    );
                    setFieldValue(
                      "bookingInfo.customerStayingIc",
                      row.original.icNo
                    );
                    setFieldValue(
                      "bookingInfo.customerStayingPhone",
                      row.original.phone
                    );
                    setFieldValue(
                      "bookingInfo.customerStayingEmail",
                      row.original.email
                    );
                    setFieldValue(
                      "bookingInfo.customerStayingMemberId",
                      row.original.memberId
                    );
                    setFieldValue(
                      "bookingInfo.customerStayingMemberTier",
                      row.original.memberTier
                    );
                    setFieldValue(
                      "bookingInfo.customerStayingMemberCondition",
                      row.original.memberCondition
                    );
                    setFieldValue(
                      "bookingInfo.customerStayingCountry",
                      row.original.countryCode
                    );
                    setFieldValue(
                      "bookingInfo.rententionCount",
                      row.original.rententionCount
                    );
                    console.log(row.original);
                    for (let i = 0; i < roomBookings.length; i++) {
                      setFieldValue(
                        `roomBookings.${i}.person_in_charge_id`,
                        row.original.customerId
                      );
                      setFieldValue(
                        `roomBookings.${i}.person_in_charge_name`,
                        row.original.fullName
                      );
                      setFieldValue(
                        `roomBookings.${i}.person_in_charge_ic`,
                        row.original.icNo
                      );
                      setFieldValue(
                        `roomBookings.${i}.person_in_charge_country`,
                        row.original.countryCode
                      );
                      setFieldValue(
                        `roomBookings.${i}.person_in_charge_phone`,
                        row.original.phone
                      );
                      setFieldValue(
                        `roomBookings.${i}.person_in_charge_email`,
                        row.original.email
                      );
                      setFieldValue(
                        `roomBookings.${i}.member_id`,
                        row.original.memberId
                      );
                      setFieldValue(
                        `roomBookings.${i}.member_tier`,
                        row.original.memberTier
                      );
                      setFieldValue(
                        `roomBookings.${i}.member_condition`,
                        row.original.memberCondition
                      );
                      setFieldValue(
                        `roomBookings.${i}.rentention_count`,
                        row.original.rententionCount
                      );
                    }
                    handleSetExistingGuestTableToClose();
                    hasGottenCustomerStayingIdRef.current = true;
                    hanldeNextButtonOnclick();
                    return;
                  }
                  setFieldValue(
                    `roomBookings.${activeIndex}.person_in_charge_id`,
                    row.original.customerId
                  );
                  setFieldValue(
                    `roomBookings.${activeIndex}.person_in_charge_name`,
                    row.original.fullName
                  );
                  setFieldValue(
                    `roomBookings.${activeIndex}.person_in_charge_ic`,
                    row.original.icNo
                  );
                  setFieldValue(
                    `roomBookings.${activeIndex}.person_in_charge_country`,
                    row.original.countryCode
                  );
                  setFieldValue(
                    `roomBookings.${activeIndex}.member_id`,
                    row.original.memberId
                  );
                  setFieldValue(
                    `roomBookings.${activeIndex}.member_tier`,
                    row.original.memberTier
                  );
                  setFieldValue(
                    `roomBookings.${activeIndex}.member_condition`,
                    row.original.memberCondition
                  );
                  setFieldValue(
                    `roomBookings.${activeIndex}.rentention_count`,
                    row.original.rententionCount
                  );
                  handleSetExistingGuestTableToClose();
                  hanldeNextButtonOnclick();
                }}
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: "semi-bold",
                }}
              >
                Assign
              </Button>
            ),
        },
      ],
      [activeIndex]
    );

    return (
      <Modal
        open={isExistingGuestTableOpen}
        onClose={() => handleSetExistingGuestTableToClose()}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          },
        }}
      >
        <Grid
          container
          width={"80%"}
          spacing={0}
          padding={0}
          justifyContent={"start"}
          alignContent={"start"}
          sx={{
            backgroundColor: theme.palette.background.default,
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            height={"50px"}
            width={"100%"}
            sx={{
              border: 2,
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
              borderBottomStyle: "none",
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
            }}
          >
            <Typography variant={"h3"} color={theme.palette.primary.main}>
              Existing Customer
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            width={"100%"}
            sx={{
              border: 2,
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
              borderBottomStyle: "none",
              display: "flex",
              alignItems: "center",
              padding: 1,
            }}
          >
            <TextField
              type={"search"}
              size={"small"}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              sx={{
                width: "100%",
                borderRadius: 1,
                border: 1,
                borderColor: theme.palette.primary.main,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            width={"100%"}
            sx={{
              border: 2,
              borderStyle: "solid",
              borderColor: theme.palette.primary.main,
            }}
          >
            <MaterialReactTable
              columns={columns}
              data={existingCustomerRows}
              state={{ pagination, isLoading, showColumnFilters: true }}
              onPaginationChange={setPagination}
              enableTopToolbar={false}
              muiTableHeadProps={{
                sx: {
                  ".MuiTableHead-root": {
                    border: 2,
                    borderStyle: "solid",
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  color: theme.palette.primary.main,
                  backgroundColor: "black",
                  ".MuiSvgIcon-root.MuiTableSortLabel-icon": {
                    color: theme.palette.primary.main,
                  },
                  borderStyle: "none",
                  borderBottomStyle: "solid",
                  borderBottomColor: theme.palette.primary.main,
                },
              }}
              muiTableHeadCellColumnActionsButtonProps={{
                sx: {
                  display: "none",
                },
              }}
              muiTableBodyProps={{
                sx: {
                  backgroundColor: theme.palette.background.default,
                  "& .MuiTypography-root": {
                    color: theme.palette.primary.main,
                  },
                },
              }}
              muiTableBodyRowProps={({ staticRowIndex }) => ({
                sx: {
                  backgroundColor:
                    staticRowIndex % 2 === 0
                      ? theme.palette.background.default
                      : "black",
                },
              })}
              muiTableBodyCellProps={{
                sx: {
                  color: "white",
                  borderBottomStyle: "solid",
                  borderBottomColor: theme.palette.primary.main,
                },
              }}
              muiBottomToolbarProps={{
                sx: {
                  backgroundColor: "black",
                },
              }}
              muiTablePaginationProps={{
                rowsPerPageOptions: [-1],
                sx: {
                  ".MuiTablePagination-displayedRows": {
                    color: theme.palette.primary.main,
                  },
                  ".MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root":
                    {
                      color: theme.palette.primary.main,
                    },
                  ".MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root.Mui-disabled .MuiSvgIcon-root":
                    {
                      color: theme.palette.primary.main,
                      opacity: 0.5,
                    },
                },
              }}
              muiLinearProgressProps={{
                sx: {
                  height: "10px",
                },
              }}
            />
          </Grid>
        </Grid>
      </Modal>
    );
  }
);

export default ExisitingCustomerTableModal;
