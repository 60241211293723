import { FileDownload, FilterAlt, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  IAdvancedDetailed,
  IAdvancedSummary,
  ILot,
  IPaymentOverview,
  IPaymentType,
} from "../../models/payment/PaymentInterface";
import axiosInstance from "../../constants/axiosConfig";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import CustomTable from "../../components/global/table/CustomTable";
import {
  camelToNormal,
  convertToReadableFormat,
  formatDocumentDateFormat,
  formatToPresentableNumber,
  stringDateToEpoch,
} from "../../utils/functions";
import {
  ArrowDropDownIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import XLSX from "sheetjs-style";
import { format } from "date-fns";
import { ReactComponent as ExcelIcon } from "../../assets/images/icons8-excel.svg";
import { ReactComponent as PDFIcon } from "../../assets/images/pdf-svgrepo-com.svg";
import Notification from "../../utils/notificationConfig";
import { IPlatform } from "../../models/bookings/Booking";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

enum reportTypeEnum {
  advancedUsed = "used",
  advancedCollected = "collected",
  advancedDepositLedger = "ledger",
}

enum groupByEnum {
  booking = "booking",
  transaction = "transaction",
  transactionDate = "transaction_date",
  checkInTime = "check_in_datetime",
}

const bookingStatuses = [
  "Check In",
  "Check Out",
  "Cancelled",
  "Booked",
  "Confirm Booking",
  "No Show",
  "Reservation",
  "Transfer from Airside",
  "Transfer from Landslide",
];

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const lotInitialValue: ILot = {
  airportCodeId: 0,
  lotDescription: "",
  lotId: 0,
  lotNumber: "",
};

const TransactionListPage = () => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);

  const currentDate = new Date();
  const currentMonthName = format(currentDate, "MMMM");

  const startOfDay = new Date(currentDate);
  startOfDay.setHours(0, 0, 0, 0);

  const startOfYear = new Date(new Date().getFullYear(), 0, 1);

  const endOfDay = new Date(currentDate);
  endOfDay.setHours(23, 59, 59, 999);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const detailedDataRef = useRef<any>(null);
  const summaryDataRef = useRef<any>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseDropdown = () => setAnchorEl(null);
  const handleOpenDropdown = ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(currentTarget);
  };

  const [reportType, setReportType] = useState<string>(
    reportTypeEnum.advancedUsed
  );
  const [version, setVersion] = useState<string>("summary");

  const [summaryData, setSummaryData] = useState<IAdvancedSummary[]>([]);
  const [detailedData, setDetailedData] = useState<IAdvancedDetailed[]>([]);

  const [startDate, setStartDate] = useState<Date>(startOfYear);
  const [endDate, setEndDate] = useState<Date>(endOfDay);
  const [groupBy, setGroupBy] = useState<string>("");
  const [bookingStatusFilter, setBookingStatusFilter] = useState<string[]>([]);
  const [bookingSourceFilter, setBookingSourceFilter] = useState<string[]>([]);
  const [paymentTypeFilter, setPaymentTypeFilter] = useState<string[]>([]);
  const [asAtDateFilter, setAsAtDateFilter] = useState<Date>();

  const [currentLot, setCurrentLot] = useState<ILot>(lotInitialValue);

  const [paymentOverview, setPaymentOverview] = useState<IPaymentOverview[]>(
    []
  );

  const fetchTransactions = () => {
    setIsLoading(true);

    const endDateTime = endDate;

    endDateTime.setHours(23, 59, 59);

    axiosInstance
      .get(`/transaction/advanced`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDateTime: startDate ? startDate : "",
          endDateTime: endDate ? endDateTime : "",
          reportType: reportType,
          versions: version,
          groupBy: groupBy,
          bookingStatus: bookingStatusFilter.join(":"),
          paymentType: paymentTypeFilter.join(":"),
          bookingSource: bookingSourceFilter.join(":"),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (version === "summary") {
            setSummaryData(
              res.data.data.map((value: any) => ({
                ...value,
                sales: parseFloat(value.sales),
                promotion: parseFloat(value.promotion),
                adjustment: parseFloat(value.adjustment),
                roundings: parseFloat(value.roundings),
                sst: parseFloat(value.sst),
                totalDeposit: parseFloat(value.totalDeposit),
              }))
            );
          } else if (version === "detailed") {
            setDetailedData(
              res.data.data.map((value: any) => ({
                ...value,
                sales: parseFloat(value.sales),
                promotion: parseFloat(value.promotion),
                adjustment: parseFloat(value.adjustment),
                roundings: parseFloat(value.roundings),
                sst: parseFloat(value.sst),
                totalDeposit: parseFloat(value.totalDeposit),
              }))
            );
          }
        }
      })
      .catch((e) => Notification.failed(e.response.data.error))
      .finally(() => setIsLoading(false));
  };

  const fetchOverviewTransactions = () => {
    axiosInstance
      .get(`/transaction/report-overview`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDateTime: startDate
            ? stringDateToEpoch(startDate.toString())
            : "",
          endDateTime: endDate ? stringDateToEpoch(endDate.toString()) : "",
          reportType: reportType,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setPaymentOverview(res.data.data);
        }
      });
  };

  const fetchCurrentLot = () => {
    axiosInstance
      .get(`/lot/get-own-lot`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setCurrentLot(res.data);
          return;
        }
        Notification.failed(res.data.msg);
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      });
  };

  useEffect(() => {
    if (version === "detailed") {
      setGroupBy(groupByEnum.booking);
      return;
    }
    if (reportType === reportTypeEnum.advancedUsed) {
      setGroupBy(groupByEnum.checkInTime);
    }
    if (
      reportType === reportTypeEnum.advancedCollected ||
      reportType === reportTypeEnum.advancedDepositLedger
    ) {
      setGroupBy(groupByEnum.transactionDate);
    }

    if (reportType !== reportTypeEnum.advancedDepositLedger) {
      setAsAtDateFilter(undefined);
    }
  }, [reportType, version]);

  useEffect(() => {
    setSummaryData([]);
    setDetailedData([]);
    fetchTransactions();
    fetchOverviewTransactions();
    fetchCurrentLot();
  }, [reportType, version, groupBy, startDate, endDate]);

  // Advanced Summary Columns
  const totalSummarySales = useMemo(
    () => summaryData.reduce((acc, curr) => acc + Number(curr.sales), 0),
    [summaryData]
  );
  const totalSummarySST = useMemo(
    () => summaryData.reduce((acc, curr) => acc + Number(curr.sst), 0),
    [summaryData]
  );
  const totalSummaryPromotion = useMemo(
    () => summaryData.reduce((acc, curr) => acc + Number(curr.promotion), 0),
    [summaryData]
  );
  const totalSummaryAdjustment = useMemo(
    () => summaryData.reduce((acc, curr) => acc + Number(curr.adjustment), 0),
    [summaryData]
  );
  const totalSummaryRounding = useMemo(
    () => summaryData.reduce((acc, curr) => acc + Number(curr.roundings), 0),
    [summaryData]
  );
  const totalSummaryDeposit = useMemo(
    () => summaryData.reduce((acc, curr) => acc + Number(curr.totalDeposit), 0),
    [summaryData]
  );

  const totalDetailedSales = useMemo(
    () => detailedData.reduce((acc, curr) => acc + Number(curr.sales), 0),
    [detailedData]
  );
  const totalDetailedPromotion = useMemo(
    () => detailedData.reduce((acc, curr) => acc + Number(curr.promotion), 0),
    [detailedData]
  );
  const totalDetailedAdjustment = useMemo(
    () => detailedData.reduce((acc, curr) => acc + Number(curr.adjustment), 0),
    [detailedData]
  );
  const totalDetailedRounding = useMemo(
    () => detailedData.reduce((acc, curr) => acc + Number(curr.roundings), 0),
    [detailedData]
  );
  const totalDetailedSST = useMemo(
    () => detailedData.reduce((acc, curr) => acc + Number(curr.sst), 0),
    [detailedData]
  );
  const totalDetailedDeposit = useMemo(
    () =>
      detailedData.reduce((acc, curr) => acc + Number(curr.totalDeposit), 0),
    [detailedData]
  );

  const handleChangeReportType = (
    reportType:
      | reportTypeEnum.advancedUsed
      | reportTypeEnum.advancedCollected
      | reportTypeEnum.advancedDepositLedger
  ): void => {
    setReportType(reportType);
  };

  const handleChangeVersion = (version: "summary" | "detailed"): void => {
    setVersion(version);
  };

  const handleChangeGroupBy = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setGroupBy(value);
  };

  const handleChangeBookingStatusFilter = (
    event: SelectChangeEvent<typeof bookingStatusFilter>
  ) => {
    const {
      target: { value },
    } = event;
    setBookingStatusFilter(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeBookingSourceFilter = (
    event: SelectChangeEvent<typeof bookingStatusFilter>
  ) => {
    const {
      target: { value },
    } = event;
    setBookingSourceFilter(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangePaymentTypeFilter = (
    event: SelectChangeEvent<typeof bookingStatusFilter>
  ) => {
    const {
      target: { value },
    } = event;
    setPaymentTypeFilter(typeof value === "string" ? value.split(",") : value);
  };

  const handleStartDateChange = (startDate: Date) => {
    setStartDate(startDate);
  };

  const handleAtDateChange = (atDateFilter: Date) => {
    setAsAtDateFilter(atDateFilter);
  };

  const handleEndDateChange = (endDate: Date) => {
    endDate.setHours(23, 59, 59, 999);
    setEndDate(endDate);
  };

  const summaryDataColumn = useMemo<MRT_ColumnDef<IAdvancedSummary>[]>(
    () => [
      {
        header: "Date",
        Cell: ({ row }) => <Typography>{row.original.date}</Typography>,
      },
      {
        header: "Sales",
        Cell: ({ row }) => (
          <Typography>
            {row.original.sales
              ? formatToPresentableNumber(row.original.sales.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Sales:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSummarySales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "SST",
        Cell: ({ row }) => (
          <Typography>
            {row.original.sst
              ? formatToPresentableNumber(row.original.sst.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total SST:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSummarySST?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Promotion",
        Cell: ({ row }) => (
          <Typography>
            {row.original.promotion
              ? formatToPresentableNumber(row.original.promotion.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Promotion:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSummaryPromotion?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Adjustment",
        Cell: ({ row }) => (
          <Typography>
            {row.original.adjustment
              ? formatToPresentableNumber(row.original.adjustment.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Adjustment:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSummaryAdjustment?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Rounding",
        Cell: ({ row }) => (
          <Typography>
            {row.original.roundings
              ? formatToPresentableNumber(row.original.roundings.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Rounding:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSummaryRounding?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Total Deposit",
        Cell: ({ row }) => (
          <Typography>
            {row.original.totalDeposit
              ? formatToPresentableNumber(row.original.totalDeposit.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Deposits:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalSummaryDeposit?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
    ],
    [
      totalSummarySales,
      totalSummarySST,
      totalSummaryPromotion,
      totalSummaryAdjustment,
      totalSummaryRounding,
      totalSummaryDeposit,
    ]
  );

  const detailedDataColumn = useMemo<MRT_ColumnDef<IAdvancedDetailed>[]>(
    () => [
      {
        header: "Payment Date",
        Cell: ({ row }) => (
          <Typography>
            {row.original.paymentDate
              ? format(new Date(row.original.paymentDate), "dd/MM/yyyy HH:mm")
              : "-"}
          </Typography>
        ),
      },
      {
        header: "Check In",
        Cell: ({ row }) => <Typography>{row.original.checkInDate}</Typography>,
      },
      {
        id: "actualCheckInDate",
        header: "Actual Check In",
        Cell: ({ row }) => (
          <Typography>{row.original.actualCheckInDate}</Typography>
        ),
      },
      {
        header: "Booking",
        Cell: ({ row }) => <Typography>{row.original.bookingId}</Typography>,
      },
      {
        header: "Booking Status",
        Cell: ({ row }) => (
          <Typography>{row.original.bookingStatus}</Typography>
        ),
      },
      {
        header: "Sales",
        Cell: ({ row }) => (
          <Typography>
            {row.original.sales
              ? formatToPresentableNumber(row.original.sales.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Sales:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalDetailedSales?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },

      {
        header: "Promotion",
        Cell: ({ row }) => (
          <Typography>
            {row.original.promotion
              ? formatToPresentableNumber(row.original.promotion.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Promotion:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalDetailedPromotion?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Adjustment",
        Cell: ({ row }) => (
          <Typography>
            {row.original.adjustment
              ? formatToPresentableNumber(row.original.adjustment.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Adjustment:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalDetailedAdjustment?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Rounding",
        Cell: ({ row }) => (
          <Typography>
            {row.original.roundings
              ? formatToPresentableNumber(row.original.roundings.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Rounding:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalDetailedRounding?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "SST",
        Cell: ({ row }) => (
          <Typography>
            {row.original.sst
              ? formatToPresentableNumber(row.original.sst.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total SST:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalDetailedSST?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: "Total Deposit",
        Cell: ({ row }) => (
          <Typography>
            {row.original.totalDeposit
              ? formatToPresentableNumber(row.original.totalDeposit.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Deposits:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: "bold" }}>
              {totalDetailedDeposit?.toLocaleString?.("en-MY", {
                style: "currency",
                currency: "MYR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
    ],
    [
      totalDetailedSales,
      totalDetailedSST,
      totalDetailedPromotion,
      totalDetailedAdjustment,
      totalDetailedRounding,
      totalDetailedDeposit,
    ]
  );

  const fileNaming = () => {
    if (startDate && endDate) {
      return `${reportType}_${version}_${formatDocumentDateFormat(
        startDate
      )}_to_${formatDocumentDateFormat(endDate)}_ByTransactions`;
    }
    if (startDate || endDate) {
      return `${reportType}_${version}_${formatDocumentDateFormat(
        startDate
      )}${formatDocumentDateFormat(endDate)}_ByTransactions`;
    }
    return `${reportType}_${version}_ByTransactions`;
    /*------------------------------------------------------------- */
  };

  const handleXLSXDownload = () => {
    const dataToExport = convertedData();

    const companyNameHeader = `Company Name: ${currentLot.lotDescription}`;
    const reportNameHeader = `Report Name: ${convertToReadableFormat(
      reportType
    )} ${convertToReadableFormat(version)}`;
    const outletHeader = `Outlet: ${currentLot.lotNumber}`;
    const monthHeader = `Month: ${currentMonthName}`;
    const startAndEndDate = `Report Date from: ${format(
      startDate,
      "dd MMMM yyyy"
    )}        to: ${format(endDate, "dd MMMM yyyy")}`;
    const dateGenerated = `Date Generated: ${format(
      new Date(),
      "dd MMMM yyyy hh:mm"
    )}`;
    const asAtDate = asAtDateFilter;
    let Heading = [
      [companyNameHeader],
      [reportNameHeader],
      [outletHeader],
      [monthHeader],
      [startAndEndDate],
      [dateGenerated],
    ];

    if (asAtDate) {
      Heading.push([`As At Date: ${format(asAtDate, "dd MMMM yyyy")}`]);
    }
    // const ws = XLSX.utils.json_to_sheet(dataToExport);
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);

    if (asAtDate) {
      XLSX.utils.sheet_add_json(ws, dataToExport, {
        origin: "A9",
      });
    } else {
      XLSX.utils.sheet_add_json(ws, dataToExport, {
        origin: "A8",
      });
    }

    const borderStyle = {
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
      { s: { r: 3, c: 0 }, e: { r: 3, c: 7 } },
      { s: { r: 4, c: 0 }, e: { r: 4, c: 7 } },
      { s: { r: 5, c: 0 }, e: { r: 5, c: 7 } },
    ];

    if (asAtDate) {
      ws["!merges"].push({ s: { r: 6, c: 0 }, e: { r: 6, c: 7 } });
    }
    Object.keys(ws).forEach((cell) => {
      if (cell !== "!ref") {
        ws[cell].s = borderStyle;
      }
    });
    ws["A1"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A2"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A3"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A4"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A5"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws["A6"].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    if (asAtDate) {
      ws["A7"].s = {
        font: {
          sz: 18,
          bold: true,
        },
      };
    }
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${fileNaming()}.xlsx`);
  };

  const handleDownloadPDF = () => {
    let ref = summaryDataRef.current;
    if (version === "detailed") {
      ref = detailedDataRef.current;
    }
    html2canvas(ref)
      .then((canvas) => {
        const contentWidth = canvas.width * 0.8;
        const contentHeight = canvas.height * 0.8;
        const pdf = new jsPDF(contentWidth > contentHeight ? "l" : "p", "mm", [
          contentWidth,
          contentHeight,
        ]);
        pdf.addImage(
          canvas.toDataURL("image/png"),
          "PNG",
          0,
          0,
          contentWidth,
          contentHeight
        ); // Adjust the size as needed
        pdf.save(`${fileNaming()}.pdf`);
      })
      .catch((error) => {
        console.error("Error converting to canvas:", error);
      });
  };

  //File Download Menu Stuffs

  const [fileDownloadAnchorEl, setFileDownloadAnchorEl] =
    useState<null | HTMLElement>(null);
  const openFileDownloadMenu = Boolean(fileDownloadAnchorEl);
  const handleFileDownloadClick = (event: React.MouseEvent<HTMLElement>) => {
    setFileDownloadAnchorEl(event.currentTarget);
  };
  const handleFileDownloadClose = () => {
    setFileDownloadAnchorEl(null);
  };

  const convertedData = () => {
    if (reportType === reportTypeEnum.advancedUsed && version === "summary") {
      return summaryData.map((data) => ({
        checkInDate: data.date,
        sales: data.sales,
        sst: data.sst,
        promotion: data.promotion,
        adjustment: data.adjustment,
        roundings: data.roundings,
        totalDepositUsed: data.totalDeposit,
      }));
    }
    if (
      reportType === reportTypeEnum.advancedCollected &&
      version === "summary"
    ) {
      return summaryData.map((data) => ({
        paymentDate: data.date,
        sales: data.sales,
        sst: data.sst,
        promotion: data.promotion,
        adjustment: data.adjustment,
        roundings: data.roundings,
        totalDepositCollected: data.totalDeposit,
      }));
    }
    if (
      reportType === reportTypeEnum.advancedDepositLedger &&
      version === "summary"
    ) {
      return summaryData.map((data) => ({
        paymentDate: data.date,
        sales: data.sales,
        sst: data.sst,
        promotion: data.promotion,
        adjustment: data.adjustment,
        roundings: data.roundings,
        totalDeposit: data.totalDeposit,
      }));
    }
    if (reportType === reportTypeEnum.advancedUsed && version === "detailed") {
      return detailedData.map((data) => ({
        paymentDate: data.paymentDate,
        checkInDate: data.checkInDate,
        actualCheckInDate: data.actualCheckInDate,
        bookingId: data.bookingId,
        bookingStatus: data.bookingStatus,
        sales: data.sales,
        sst: data.sst,
        promotion: data.promotion,
        adjustment: data.adjustment,
        rounding: data.roundings,
        totalDepositUsed: data.totalDeposit,
      }));
    }
    if (
      reportType === reportTypeEnum.advancedCollected &&
      version === "detailed"
    ) {
      return detailedData.map((data) => ({
        paymentDate: data.paymentDate,
        checkInDate: data.checkInDate,
        bookingId: data.bookingId,
        bookingStatus: data.bookingStatus,
        sales: data.sales,
        sst: data.sst,
        promotion: data.promotion,
        adjustment: data.adjustment,
        rounding: data.roundings,
        totalDepositCollected: data.totalDeposit,
      }));
    }
    if (
      reportType === reportTypeEnum.advancedDepositLedger &&
      version === "detailed"
    ) {
      return detailedData.map((data) => ({
        paymentDate: data.paymentDate,
        checkInDate: data.checkInDate,
        bookingId: data.bookingId,
        bookingStatus: data.bookingStatus,
        sales: data.sales,
        sst: data.sst,
        promotion: data.promotion,
        adjustment: data.adjustment,
        rounding: data.roundings,
        totalDepositCollected: data.totalDeposit,
      }));
    }
    Notification.warning("Something is wrong with data conversion");
    return [];
  };

  return (
    <Box
      display={"flex"}
      bgcolor={theme.palette.background.default}
      maxWidth={"90vw"}
      border={2}
      borderColor={theme.palette.primary.main}
    >
      <Stack direction={"row"} width={"100%"}>
        <Stack
          direction={"column"}
          width={"18%"}
          borderRight={2}
          borderColor={theme.palette.primary.main}
        >
          <Box
            display={"flex"}
            bgcolor={theme.palette.background.default}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            height={"100px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="h2">Overview</Typography>
          </Box>

          <Stack height={"100%"} spacing={1}>
            {paymentOverview.map((overview) => (
              <Box display={"flex"} flexDirection={"column"} padding={1}>
                <Box>
                  <Typography fontWeight={600}>
                    {overview.left.name} | {overview.right.name}
                  </Typography>
                  <Box
                    width={"100%"}
                    height={"30px"}
                    border={"2px solid white"}
                    display={"flex"}
                    marginTop={1}
                  >
                    <Box
                      width={"50%"}
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        color={"black"}
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        fontWeight={600}
                      >
                        {overview.left.value}
                      </Typography>
                    </Box>
                    <Box
                      width={"50%"}
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        backgroundColor: "black",
                      }}
                    >
                      <Typography
                        color={"white"}
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        fontWeight={600}
                      >
                        {overview.right.value}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
        </Stack>
        <Stack direction={"column"} width={"82%"}>
          <Accordion>
            <AccordionSummary>
              <Box
                display={"flex"}
                width={"100%"}
                height={"100px"}
                justifyContent={"space-between"}
                paddingX={2}
                alignItems={"center"}
                bgcolor={theme.palette.background.default}
                borderBottom={2}
                borderColor={theme.palette.primary.main}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <FilterAlt sx={{ fontSize: "32px", color: "white" }} />
                  <Typography variant="h3">Filter</Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      fetchTransactions();
                      fetchOverviewTransactions();
                    }}
                    sx={{ borderRadius: 1 }}
                  >
                    <Search />
                  </Button>
                  <Button
                    id="file-download-button"
                    aria-controls={
                      openFileDownloadMenu ? "file-download-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFileDownloadMenu ? "true" : undefined}
                    variant="contained"
                    onClick={handleFileDownloadClick}
                    sx={{ borderRadius: 1 }}
                  >
                    <FileDownload />
                  </Button>
                  <StyledMenu
                    id="file-download-menu"
                    MenuListProps={{
                      "aria-labelledby": "file-download-button",
                    }}
                    anchorEl={fileDownloadAnchorEl}
                    open={openFileDownloadMenu}
                    onClose={handleFileDownloadClose}
                  >
                    <MenuItem onClick={handleDownloadPDF} disableRipple>
                      <PDFIcon
                        width={"35px"}
                        height={"35px"}
                        style={{ marginRight: "10px" }}
                      />
                      PDF
                    </MenuItem>
                    <MenuItem onClick={handleXLSXDownload} disableRipple>
                      <ExcelIcon
                        width={"35px"}
                        height={"35px"}
                        style={{ marginRight: "10px" }}
                      />
                      Xlsx
                    </MenuItem>
                    {/* <CSVLink
                      data={                     }
                      filename={`${fileNaming()}.csv`}
                    >
                      <MenuItem onClick={handleFileDownloadClose} disableRipple>
                        <ExcelIcon
                          width={"35px"}
                          height={"35px"}
                          style={{ marginRight: "10px" }}
                        />
                        CSV
                      </MenuItem>
                    </CSVLink> */}
                  </StyledMenu>
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box display={"flex"} flexDirection={"column"}>
                <AdvancedReportFilter
                  anchorEl={anchorEl}
                  reportType={reportType}
                  version={version}
                  bookingStatusFilter={bookingStatusFilter}
                  bookingSourceFilter={bookingSourceFilter}
                  paymentTypeFilter={paymentTypeFilter}
                  groupBy={groupBy}
                  handleChangeGrouping={handleChangeGroupBy}
                  handleChangeReportType={handleChangeReportType}
                  handleChangeVersion={handleChangeVersion}
                  handleCloseDropdown={handleCloseDropdown}
                  handleOpenDropdown={handleOpenDropdown}
                  handleChangeBookingStatusFilter={
                    handleChangeBookingStatusFilter
                  }
                  handleChangeBookingSourceFilter={
                    handleChangeBookingSourceFilter
                  }
                  handleChangePaymentTypeFilter={handleChangePaymentTypeFilter}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box display={"flex"} alignItems={"center"}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(e) => handleStartDateChange(e as Date)}
                      format="dd MMM yyyy"
                      slots={{
                        openPickerIcon: ArrowDropDownIcon,
                      }}
                      slotProps={{
                        layout: {
                          sx: {
                            ".MuiDateCalendar-root": {
                              color: theme.palette.primary.main,
                              borderRadius: 2,
                              borderWidth: 1,
                              borderColor: theme.palette.primary.main,
                              border: "1px solid",
                              backgroundColor: theme.palette.background.paper,
                            },
                            ".MuiButtonBase-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiTypography-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersCalendarHeader-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersMonth-monthButton": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersCalendarHeader-label": {
                              color: theme.palette.primary.main,
                            },
                            ".Mui-selected": {
                              color: "black",
                            },
                            ".MuiPickersYear-yearButton": {
                              color: theme.palette.primary.main,
                            },
                          },
                        },
                        textField: {
                          variant: "filled",
                          inputProps: {
                            style: {
                              color: "white",
                              borderRadius: 1,
                              border: 2,
                            },
                          },
                          sx: {
                            width: "150px",
                            backgroundColor: "#232323",
                            border: "1px solid #148978",
                            borderRadius: 1,
                          },
                        },
                        openPickerButton: {
                          sx: {
                            color: "white",
                            marginTop: "1.8px",
                            zIndex: 1,
                          },
                        },
                        openPickerIcon: {
                          sx: {
                            width: "1em",
                            height: "1em",
                            zIndex: 1,
                          },
                        },
                      }}
                    />
                    <Typography variant="h3" marginX={1}>
                      -
                    </Typography>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(e) => handleEndDateChange(e as Date)}
                      format="dd MMM yyyy"
                      slots={{
                        openPickerIcon: ArrowDropDownIcon,
                      }}
                      slotProps={{
                        layout: {
                          sx: {
                            ".MuiDateCalendar-root": {
                              color: theme.palette.primary.main,
                              borderRadius: 2,
                              borderWidth: 1,
                              borderColor: theme.palette.primary.main,
                              border: "1px solid",
                              backgroundColor: theme.palette.background.paper,
                            },
                            ".MuiButtonBase-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiTypography-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersCalendarHeader-root": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersMonth-monthButton": {
                              color: theme.palette.primary.main,
                            },
                            ".MuiPickersCalendarHeader-label": {
                              color: theme.palette.primary.main,
                            },
                            ".Mui-selected": {
                              color: "black",
                            },
                            ".MuiPickersYear-yearButton": {
                              color: theme.palette.primary.main,
                            },
                          },
                        },
                        textField: {
                          variant: "filled",
                          inputProps: {
                            style: {
                              color: "white",
                              borderRadius: 1,
                              border: 2,
                            },
                          },
                          sx: {
                            width: "150px",
                            backgroundColor: "#232323",
                            border: "1px solid #148978",
                            borderRadius: 1,
                          },
                        },
                        openPickerButton: {
                          sx: {
                            color: "white",
                            marginTop: "1.8px",
                            zIndex: 1,
                          },
                        },
                        openPickerIcon: {
                          sx: {
                            width: "1em",
                            height: "1em",
                            zIndex: 1,
                          },
                        },
                      }}
                    />
                    {reportType === reportTypeEnum.advancedDepositLedger && (
                      <DatePicker
                        label="As At Date"
                        value={asAtDateFilter}
                        onChange={(e) => handleAtDateChange(e as Date)}
                        format="dd MMM yyyy"
                        slots={{
                          openPickerIcon: ArrowDropDownIcon,
                        }}
                        slotProps={{
                          layout: {
                            sx: {
                              ".MuiDateCalendar-root": {
                                color: theme.palette.primary.main,
                                borderRadius: 2,
                                borderWidth: 1,
                                borderColor: theme.palette.primary.main,
                                border: "1px solid",
                                backgroundColor: theme.palette.background.paper,
                              },
                              ".MuiButtonBase-root": {
                                color: theme.palette.primary.main,
                              },
                              ".MuiTypography-root": {
                                color: theme.palette.primary.main,
                              },
                              ".MuiPickersCalendarHeader-root": {
                                color: theme.palette.primary.main,
                              },
                              ".MuiPickersMonth-monthButton": {
                                color: theme.palette.primary.main,
                              },
                              ".MuiPickersCalendarHeader-label": {
                                color: theme.palette.primary.main,
                              },
                              ".Mui-selected": {
                                color: "black",
                              },
                              ".MuiPickersYear-yearButton": {
                                color: theme.palette.primary.main,
                              },
                            },
                          },
                          textField: {
                            variant: "filled",
                            inputProps: {
                              style: {
                                color: "white",
                                borderRadius: 1,
                                border: 2,
                              },
                            },
                            sx: {
                              width: "150px",
                              backgroundColor: "#232323",
                              border: "1px solid #148978",
                              borderRadius: 1,
                              marginLeft: 1,
                            },
                          },
                          openPickerButton: {
                            sx: {
                              color: "white",
                              marginTop: "1.8px",
                              zIndex: 1,
                            },
                          },
                          openPickerIcon: {
                            sx: {
                              width: "1em",
                              height: "1em",
                              zIndex: 1,
                            },
                          },
                        }}
                      />
                    )}
                  </Box>
                </LocalizationProvider>
              </Box>
            </AccordionDetails>
          </Accordion>
          {isLoading ? (
            <Box
              display={"flex"}
              height={"500px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box height={"100%"} width={"100%"}>
              {version === "summary" ? (
                <>
                  <CustomTable
                    key={"summaryData"}
                    isLoading={isLoading}
                    columns={summaryDataColumn}
                    data={summaryData}
                    handlePaginationChange={setPagination}
                    pagination={pagination}
                    enableTopToolbar={false}
                    oddEvenBackground={true}
                  />
                  <div
                    ref={summaryDataRef}
                    style={{
                      position: "absolute",
                      left: "-9999px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box display={"flex"} flexDirection={"column"} padding={2}>
                      <Typography variant="h4" color={"black"}>
                        Company Name: {currentLot.lotDescription}
                      </Typography>
                      <Typography variant="h4" color={"black"}>
                        Report Name: {convertToReadableFormat(reportType)}{" "}
                        {convertToReadableFormat(version)}
                      </Typography>
                      <Typography variant="h4" color={"black"}>
                        Outlet: {currentLot.lotNumber}
                      </Typography>
                      <Typography variant="h4" color={"black"}>
                        Month: {currentMonthName}
                      </Typography>
                      <Typography variant="h4" color={"black"}>
                        Date Generated:{" "}
                        {format(new Date(), "dd MMMM yyyy hh:mm")}
                      </Typography>
                      {reportType === reportTypeEnum.advancedDepositLedger &&
                        asAtDateFilter && (
                          <Typography variant="h4" color={"black"}>
                            As At Date:{" "}
                            {format(asAtDateFilter, "dd MMMM yyyy hh:mm")}
                          </Typography>
                        )}
                    </Box>
                    <CustomTable
                      key={"summaryDataPrint"}
                      isLoading={isLoading}
                      data={summaryData}
                      columns={summaryDataColumn}
                      enableTopToolbar={false}
                      rowCount={summaryData.length}
                      pagination={{
                        pageIndex: 0,
                        pageSize: summaryData.length,
                      }}
                      oddEvenBackground={true}
                    />
                  </div>
                </>
              ) : (
                <>
                  <CustomTable
                    key={"detailedData"}
                    isLoading={isLoading}
                    columns={detailedDataColumn}
                    data={detailedData}
                    handlePaginationChange={setPagination}
                    pagination={pagination}
                    enableTopToolbar={false}
                    oddEvenBackground={true}
                    columnVisibility={{
                      actualCheckInDate:
                        reportType === reportTypeEnum.advancedUsed,
                    }}
                  />
                  <div
                    ref={detailedDataRef}
                    style={{
                      position: "absolute",
                      left: "-9999px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box display={"flex"} flexDirection={"column"} padding={2}>
                      <Typography variant="h4" color={"black"}>
                        Company Name: {currentLot.lotDescription}
                      </Typography>
                      <Typography variant="h4" color={"black"}>
                        Report Name: {convertToReadableFormat(reportType)}{" "}
                        {convertToReadableFormat(version)}
                      </Typography>
                      <Typography variant="h4" color={"black"}>
                        Outlet: {currentLot.lotNumber}
                      </Typography>
                      <Typography variant="h4" color={"black"}>
                        Month: {currentMonthName}
                      </Typography>
                      <Typography variant="h4" color={"black"}>
                        Date Generated:{" "}
                        {format(new Date(), "dd MMMM yyyy hh:mm")}
                      </Typography>
                      {reportType === reportTypeEnum.advancedDepositLedger &&
                        asAtDateFilter && (
                          <Typography variant="h4" color={"black"}>
                            As At Date:{" "}
                            {format(asAtDateFilter, "dd MMMM yyyy hh:mm")}
                          </Typography>
                        )}
                    </Box>
                    <CustomTable
                      key={"detailedDataPrint"}
                      isLoading={isLoading}
                      data={detailedData}
                      columns={detailedDataColumn}
                      enableTopToolbar={false}
                      rowCount={detailedData.length}
                      pagination={{
                        pageIndex: 0,
                        pageSize: detailedData.length,
                      }}
                      oddEvenBackground={true}
                    />
                  </div>
                </>
              )}
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default TransactionListPage;

const AdvancedReportFilter = (props: {
  handleOpenDropdown: ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => void;
  handleCloseDropdown: VoidFunction;
  anchorEl: HTMLElement | null;
  reportType: string;
  handleChangeReportType: (
    reportType:
      | reportTypeEnum.advancedUsed
      | reportTypeEnum.advancedCollected
      | reportTypeEnum.advancedDepositLedger
  ) => void;
  version: string;
  handleChangeVersion: (version: "summary" | "detailed") => void;
  groupBy: string;
  handleChangeGrouping: (event: SelectChangeEvent<string>) => void;
  bookingStatusFilter: string[];
  handleChangeBookingStatusFilter: (event: SelectChangeEvent<string[]>) => void;
  bookingSourceFilter: string[];
  handleChangeBookingSourceFilter: (event: SelectChangeEvent<string[]>) => void;
  paymentTypeFilter: string[];
  handleChangePaymentTypeFilter: (event: SelectChangeEvent<string[]>) => void;
}) => {
  const token = useSelector((state: RootState) => state.user.accessToken);

  const [allPlatforms, setAllPlatforms] = useState<IPlatform[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<IPaymentType[]>([]);
  const fetchAllAvailablePlatform = () => {
    axiosInstance
      .get("/bookings/platforms/get-all-platforms", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setAllPlatforms(parsePlatformData(res.data.datas));
        }
      })
      .catch((e) => {
        Notification.failed("something wrong, please try again");
      });
  };

  const fetchPaymentMethod = () => {
    axiosInstance
      .get("/payment/type", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setPaymentTypes(res.data);
          return;
        }
        Notification.failed(res.data.msg);
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      });
  };

  useEffect(() => {
    fetchAllAvailablePlatform();
    fetchPaymentMethod();
  }, []);
  return (
    <Stack direction={"row"} width={"100%"} spacing={2} marginBottom={2}>
      <FormControl>
        <InputLabel variant="filled">Report Type</InputLabel>
        <Select
          variant="filled"
          value={"Report Type"}
          renderValue={(value) =>
            props.reportType ? camelToNormal(props.reportType) : value
          }
          SelectDisplayProps={{
            onClick: props.handleOpenDropdown,
          }}
          MenuProps={{
            keepMounted: true,
            sx: {
              ".MuiMenu-list": {
                color: "black",
              },
            },
          }}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          <MenuItem
            value={"Report Type"}
            disabled
            sx={{
              display: "none",
            }}
          >
            Report Type
          </MenuItem>
          <MenuItem>
            <RadioGroup
              name={"report-type"}
              value={props.reportType}
              onChange={({ target: { value } }) =>
                props.handleChangeReportType(
                  value as
                    | reportTypeEnum.advancedUsed
                    | reportTypeEnum.advancedCollected
                    | reportTypeEnum.advancedDepositLedger
                )
              }
            >
              <FormControlLabel
                value={reportTypeEnum.advancedUsed}
                control={<Radio />}
                label="Advance Used"
              />
              <FormControlLabel
                value={reportTypeEnum.advancedCollected}
                control={<Radio />}
                label="Advance Collected"
              />
              <FormControlLabel
                value={reportTypeEnum.advancedDepositLedger}
                control={<Radio />}
                label="Advance Deposit Ledger"
              />
            </RadioGroup>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Versions</InputLabel>
        <Select
          variant="filled"
          value={"Versions"}
          renderValue={(value) =>
            props.version ? camelToNormal(props.version) : value
          }
          SelectDisplayProps={{
            onClick: props.handleOpenDropdown,
          }}
          MenuProps={{
            keepMounted: true,
            sx: {
              ".MuiMenu-list": {
                color: "black",
              },
            },
          }}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          <MenuItem
            value={"Versions"}
            disabled
            sx={{
              display: "none",
            }}
          >
            Versions
          </MenuItem>
          <MenuItem>
            <RadioGroup
              name={"versions"}
              value={props.version}
              onChange={({ target: { value } }) =>
                props.handleChangeVersion(value as "detailed" | "summary")
              }
            >
              <FormControlLabel
                value="summary"
                control={<Radio />}
                label="Summary"
              />
              <FormControlLabel
                value="detailed"
                control={<Radio />}
                label="Detailed"
              />
            </RadioGroup>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Group By</InputLabel>
        <Select
          variant="filled"
          value={"grouping"}
          renderValue={(value) =>
            props.groupBy ? camelToNormal(props.groupBy) : value
          }
          SelectDisplayProps={{
            onClick: props.handleOpenDropdown,
          }}
          MenuProps={{
            keepMounted: true,
            sx: {
              ".MuiMenu-list": {
                color: "black",
              },
            },
          }}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          <MenuItem>
            <RadioGroup
              name={"groupBy"}
              value={props.groupBy}
              onChange={(event) => props.handleChangeGrouping(event)}
            >
              <FormControlLabel
                value="booking"
                control={<Radio />}
                label="Booking"
              />
              <FormControlLabel
                value="transaction"
                control={<Radio />}
                label="Transaction"
              />
            </RadioGroup>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Booking Status</InputLabel>
        <Select
          variant="filled"
          id={"booking-status-select-id"}
          multiple
          value={props.bookingStatusFilter}
          onChange={props.handleChangeBookingStatusFilter}
          renderValue={(selected) => selected.join(",")}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
        >
          {bookingStatuses.map((status, index) => (
            <MenuItem key={index} value={status}>
              <Checkbox
                checked={props.bookingStatusFilter.indexOf(status) > -1}
              />
              <ListItemText primary={status} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Booking Source</InputLabel>
        <Select
          variant="filled"
          id={"booking-source-select-id"}
          multiple
          value={props.bookingSourceFilter}
          onChange={props.handleChangeBookingSourceFilter}
          renderValue={(selected) => selected.join(",")}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 400,
              },
            },
          }}
        >
          {allPlatforms.map((platform, index) => (
            <MenuItem key={index} value={platform.platform}>
              <Checkbox
                checked={
                  props.bookingSourceFilter.indexOf(platform.platform) > -1
                }
              />
              <ListItemText primary={platform.platform} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Payment Type</InputLabel>
        <Select
          variant="filled"
          id={"payment-type-select-id"}
          multiple
          value={props.paymentTypeFilter}
          onChange={props.handleChangePaymentTypeFilter}
          renderValue={(selected) => selected.join(",")}
          sx={{
            width: "150px",
            ".MuiSelect-select": {
              border: "1px solid #148978",
            },
            ".MuiSelect-icon": {
              fill: "white",
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 400,
              },
            },
          }}
        >
          {paymentTypes.map((paymentType, index) => (
            <MenuItem key={index} value={paymentType.paymentType}>
              <Checkbox
                checked={
                  props.paymentTypeFilter.indexOf(paymentType.paymentType) > -1
                }
              />
              <ListItemText primary={paymentType.paymentType} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

const parsePlatformData = (fetchedPlatform: any[]): IPlatform[] => {
  if (fetchedPlatform) {
    return fetchedPlatform.map((item: any) => ({
      platformId: item.platform_id.toString(),
      platform: item.platform,
    }));
  } else {
    return [];
  }
};

function formatNumber(number: string) {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
