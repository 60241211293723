import { SidebarListInterface } from "../models/sidebar/sidebarMenu";

export const drawerWidth: number = 300;

export const sidebarItemList: SidebarListInterface[] = [
  {
    title: "General",
    items: [
      {
        haveDropdown: false,
        icon: "home",
        text: "Home",
        url: "/",
        visiblePermission: ["DASHBOARD"],
      },
      {
        haveDropdown: true,
        icon: "computer",
        text: "Bookings",
        visiblePermission: ["BOOKINGS", "OTA"],
        dropdownItem: [
          {
            text: "Upcoming Bookings",
            url: "/upcoming-booking",
            visiblePermission: ["BOOKINGS"],
          },

          {
            text: "Express Booking",
            url: "/express-booking",
            visiblePermission: ["BOOKINGS"],
          },
          {
            text: "New Booking",
            url: "/room-list",
            visiblePermission: ["BOOKINGS"],
          },
          {
            text: "OTA Reservation",
            url: "/ota-reservation",
            visiblePermission: ["OTA"],
          },
          {
            text: "Hotel Policy",
            url: "/consent-form",
          },
        ],
      },
      {
        haveDropdown: true,
        icon: "group",
        text: "Guests",
        visiblePermission: ["GUEST"],
        dropdownItem: [
          {
            text: "Current Inhouse Guest",
            url: "/current-inhouse-guest",
            visiblePermission: ["BOOKINGS", "GUEST"],
          },
          {
            text: "Guest Feedbacks",
            url: "/feedback-statistic",
            visiblePermission: ["GUEST"],
          },
          {
            text: "Guest Tracking",
            url: "/guest-tracking",
            visiblePermission: ["GUEST"],
          },
        ],
      },
      {
        haveDropdown: true,
        icon: "merchandise",
        text: "Merchandise",
        visiblePermission: ["MERCH"],
        dropdownItem: [
          {
            text: "Merchandise Management",
            url: "/merchs",
            visiblePermission: ["MERCH"],
          },
        ],
      },
      {
        haveDropdown: true,
        icon: "promotion",
        text: "Promotion",
        visiblePermission: ["PROMO", "PROMOUSAGE"],
        dropdownItem: [
          {
            text: "Promotion Management",
            url: "/promotions",
            visiblePermission: ["PROMO"],
          },
          {
            text: "Promotion Usage",
            url: "/promotions/usage",
            visiblePermission: ["PROMOUSAGE"],
          },
        ],
      },
      {
        haveDropdown: true,
        icon: "dollar",
        text: "POS Sales",
        visiblePermission: ["POS"],
        dropdownItem: [
          {
            text: "POS Sales",
            url: "/pos",
            visiblePermission: ["POS"],
          },
          {
            text: "POS Sales History",
            url: "/pos/history",
            visiblePermission: ["POS"],
          },
          {
            text: "POS Bookings",
            url: "/pos/pos-bookings",
            visiblePermission: ["POS"],
          },
        ],
      },
      {
        haveDropdown: true,
        icon: "chart",
        text: "Reports",
        visiblePermission: ["REPORTS"],
        dropdownItem: [
          {
            text: "Z-Report",
            url: "/z-report",
            visiblePermission: ["REPORTS"],
          },
          {
            text: "Sales & Collection",
            url: "/sales",
            visiblePermission: ["REPORTS"],
          },
          {
            text: "Ledgers",
            url: "/ledgers",
            visiblePermission: ["REPORTS"],
          },
          {
            text: "Advanced Report",
            url: "/advanced-report",
            visiblePermission: ["REPORTS"],
          },
        ],
      },
    ],
  },
  {
    title: "Management",
    visiblePermission: [
      "ROOM",
      "LOCKER",
      "SYSTEMSETTINGS",
      "ENDSHIFT",
      "HOUSEKEEPING",
    ],
    items: [
      {
        haveDropdown: true,
        icon: "person",
        text: "Admin",
        visiblePermission: ["ROOM", "LOCKER", "SYSTEMSETTINGS"],
        dropdownItem: [
          {
            text: "Room Management",
            url: "/room-management",
            visiblePermission: ["ROOM"],
          },
          {
            text: "Locker Management",
            url: "/lockers",
            visiblePermission: ["LOCKER"],
          },
          {
            text: "General Settings",
            url: "/general-settings",
            visiblePermission: ["SYSTEMSETTINGS"],
          },
          {
            text: "Payment Terminal",
            url: "/payment-terminal",
            visiblePermission: ["SYSTEMSETTINGS"],
          },
          {
            text: "Cashier Management",
            url: "/cashier-management",
            visiblePermission: ["SYSTEMSETTINGS"],
          },
          // {
          //   text: "Guest",
          //   url: "/guest",
          //   visiblePermission: ["SYSTEMSETTINGS"],
          // },
        ],
      },
      {
        haveDropdown: true,
        icon: "phone",
        text: "Supervisor",
        visiblePermission: ["ENDSHIFT", "HOUSEKEEPING"],
        dropdownItem: [
          {
            text: "HouseKeeping",
            url: "/housekeeping",
            visiblePermission: ["HOUSEKEEPING"],
          },
          {
            text: "End Shift Report",
            url: "/shiftreport",
            visiblePermission: ["ENDSHIFT"],
          },
        ],
      },
    ],
  },
];
