import {
  Box,
  Button,
  ButtonBase,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
  ListSubheader,
} from "@mui/material";
import { IPayments } from "../../models/payment/PaymentInterface";
import { ArrowForward, MoreHoriz } from "@mui/icons-material";
import Status from "../global/status/Status";
import { useEffect, useState } from "react";
import { convertTo2Decimals } from "../../utils/paymentFunction";
import {
  bookingStatusEnum,
  transactionStatusEnum,
} from "../../constants/enums";
import Notification from "../../utils/notificationConfig";
import { IPromotion } from "../../types/promotion";
import axiosInstance from "../../constants/axiosConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LayersIcon from "@mui/icons-material/Layers";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import AuthModal from "../account/AuthModal";
import AdjustmentModal from "./AdjustmentModal";

const adjustmentPromotion: IPromotion = {
  archived: false,
  details: "-",
  memberOnly: false,
  promoCode: "Adjustment",
  promoEndDatetime: "",
  promoStartDatetime: "",
  promotionId: "adjustment",
  seats: 1000,
  stackable: true,
  visibleAtWebsite: false,
};

const roundingPromotion: IPromotion = {
  archived: false,
  details: "-",
  memberOnly: false,
  promoCode: "Rounding",
  promoEndDatetime: "",
  promoStartDatetime: "",
  promotionId: "rounding",
  seats: 1000,
  stackable: true,
  visibleAtWebsite: false,
};

interface PaymentBoxProps {
  data: IPayments;
  guest: {
    guestName: string;
    isMember: boolean;
  };
  handlePaymentModalOpen: (data: IPayments) => void;
  index: number;
  handleAddPromotion: (
    promotion: IPromotion,
    poaIndex: number,
    saved: boolean
  ) => void;
  latestBookingStatus: string;
  setTransactionCompleted: React.Dispatch<React.SetStateAction<IPayments[]>>;
  fetchLoading: boolean;
  guestId: string;
}

const PaymentBox = ({
  data,
  guest,
  handlePaymentModalOpen,
  index,
  handleAddPromotion,
  latestBookingStatus,
  setTransactionCompleted,
  fetchLoading,
  guestId,
}: PaymentBoxProps) => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleOpenMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMoreMenu = () => {
    setAnchorEl(null);
  };

  const [authModalOpen, setAuthModalOpen] = useState<boolean>(false);

  const handleAuthModalOpen = () => {
    // if (latestBookingStatus === bookingStatusEnum.noShow) {
    //   Notification.failed("Only checked in bookings can proceed with void");
    //   return;
    // }
    setAuthModalOpen(true);
  };
  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };

  const [promotions, setPromotions] = useState<IPromotion[]>([]);
  const [individualPromotions, setIndividualPromotions] = useState<
    IPromotion[]
  >([]);

  const [selectedPromotion, setSelectedPromotion] = useState<string>("");

  const [openAdjustmentModal, setOpenAdjustmentModal] = useState<string>("");

  const handleAdjustmentModalOpen = (type: string) => {
    setOpenAdjustmentModal(type);
  };
  const handleAdjustmentModalClose = () => {
    setOpenAdjustmentModal("");
  };

  useEffect(() => {
    const apiUrl = `/promotions/`;
    axiosInstance
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPromotions([
          roundingPromotion,
          adjustmentPromotion,
          ...response.data.promotions.filter(
            (promotion: IPromotion) => promotion.archived === false
          ),
        ]);
      });
  }, []);

  useEffect(() => {
    const apiUrl = `/promotions/individual-promo/list`;
    axiosInstance
      .get(apiUrl, {
        params: {
          customerId: guestId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === "failed") {
          return;
        }
        setIndividualPromotions(
          response.data.data.map((data: any) => ({
            ...data,
            promotionId: data.individualPromoId,
            visibleAtWebsite: true,
          }))
        );
      });
  }, [token]);

  const addPromotion = (adjustmentPromotionProps?: IPromotion) => {
    if (!adjustmentPromotionProps) {
      const applyPromotion = promotions.filter(
        (promotion) => promotion.promotionId === selectedPromotion
      )[0];

      if (applyPromotion.promoCode === "Adjustment") {
        handleAdjustmentModalOpen("Adjustment");
        return;
      }
      if (applyPromotion.promoCode === "Rounding") {
        handleAdjustmentModalOpen("Rounding");
        return;
      }

      if (applyPromotion.seats < 1) {
        Notification.failed("Promotion have no seats left");
        return;
      }

      if (!guest.isMember && applyPromotion.memberOnly) {
        Notification.failed("Only member is elligible for this Promotion");
        return;
      }

      //Remove All promotion if selected is not stackable
      if (!applyPromotion.stackable) {
        setPromotions([roundingPromotion, adjustmentPromotion]);
      }

      //Remove Promotions that are not stackable
      if (applyPromotion.stackable) {
        setPromotions((prevPromotion) =>
          prevPromotion.filter(
            (promotion) =>
              promotion.stackable === true &&
              promotion.promotionId !== applyPromotion.promotionId
          )
        );
      }

      handleAddPromotion(applyPromotion, index, true);
    } else {
      handleAddPromotion(adjustmentPromotionProps, index, true);
    }
  };

  const handleVoidPOA = () => {
    const apiUrl = "/transaction/update-status/";

    const formData = {
      transactionId: data.transactionId,
      paymentTypeId: "-",
      status: transactionStatusEnum.void,
    };

    axiosInstance
      .patch(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Notification.success("Payment Successfully Void");
        setTransactionCompleted((prevTransaction) => {
          const removedTransaction = prevTransaction.find(
            (transaction) => transaction.transactionId === data.transactionId
          );

          if (removedTransaction) {
            const newTransactions = prevTransaction.filter(
              (transaction) => transaction.transactionId !== data.transactionId
            );

            return [
              {
                ...data,
                transactionStatus: transactionStatusEnum.void,
              },
              ...newTransactions,
            ];
          }
          return prevTransaction;
        });
      })
      .catch((res) => {
        Notification.failed(res.response.data.message);
      });
  };

  return (
    <Box
      height={"100%"}
      width={"100%"}
      border={2}
      borderColor={theme.palette.primary.main}
    >
      <Stack
        direction={"row"}
        width={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingX={1}
        borderBottom={2}
        borderColor={theme.palette.primary.main}
        height={"30px"}
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <Typography
          width={"80%"}
          color={theme.palette.primary.main}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {data.invoiceNo} - {data.customer}
        </Typography>
        {data.transactionStatus !== transactionStatusEnum.refund &&
          data.transactionStatus !== transactionStatusEnum.void && (
            <IconButton onClick={handleOpenMoreMenu}>
              <MoreHoriz color="primary" />
            </IconButton>
          )}
      </Stack>
      <Box
        height={"140px"}
        borderBottom={2}
        borderColor={theme.palette.primary.main}
      >
        <Grid container direction={"row"} width={"100%"} height={"100%"}>
          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
            <Box
              width={"100%"}
              height={"100%"}
              borderRight={2}
              borderColor={theme.palette.primary.main}
              paddingTop={"30px"}
              paddingBottom={"10px"}
              paddingX={2}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Box>
                  <Typography variant="h5">Status</Typography>
                  <Status status="Pending Payment" />
                </Box>
                <Box>
                  <Typography textAlign={"end"} variant="h5">
                    Total
                  </Typography>
                  <Typography textAlign={"end"} marginTop={"10px"} variant="h4">
                    MYR {convertTo2Decimals(data.creditAmount).toFixed(2)}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
            <ButtonBase
              onClick={() => handlePaymentModalOpen(data)}
              sx={{ width: "100%", height: "100%" }}
            >
              <Box
                display={"flex"}
                width={"100%"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                border={1}
                paddingY={"5px"}
                borderColor={theme.palette.primary.main}
                sx={{ backgroundColor: theme.palette.background.default }}
              >
                <Stack alignItems={"start"}>
                  <ArrowForward sx={{ fontSize: "50px" }} />
                  <Typography variant="h4">Payment</Typography>
                </Stack>
              </Box>
            </ButtonBase>
          </Grid>
        </Grid>
      </Box>
      <Box
        paddingX={"20px"}
        marginTop={{ xs: "30px", sm: "30px", md: "30px" }}
        height={"388px"}
      >
        <Grid container direction={"column"} justifyContent={"center"}>
          <Grid item borderBottom={2} borderColor={theme.palette.primary.main}>
            <Grid container paddingY={"10px"} paddingLeft={"10px"}>
              <Grid item md={7}>
                <Typography
                  variant={"h5"}
                  color={theme.palette.primary.main}
                  fontWeight={600}
                >
                  Item
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography
                  variant={"h5"}
                  color={theme.palette.primary.main}
                  fontWeight={600}
                >
                  Qty
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography
                  variant={"h5"}
                  color={theme.palette.primary.main}
                  fontWeight={600}
                >
                  Charges
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {data.items.map((item, index) => (
            <Grid
              item
              key={index}
              borderBottom={2}
              borderColor={"#043b33"}
              height={"42px"}
            >
              <Grid
                container
                paddingLeft={"10px"}
                alignItems={"center"}
                marginTop={"10px"}
              >
                <Grid item md={7}>
                  <Typography
                    variant={"h5"}
                    fontWeight={500}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "250px",
                    }}
                  >
                    {item.itemName} - {item.itemType}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography variant={"h5"} fontWeight={500}>
                    {item.quantity}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant={"h5"} fontWeight={500}>
                    {item.quantity
                      ? `RM ${convertTo2Decimals(
                          item.price * item.quantity
                        ).toFixed(2)}`
                      : `RM ${convertTo2Decimals(item.price).toFixed(2)}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        width={"100%"}
        height={"42px"}
        borderTop={2}
        borderColor={theme.palette.primary.main}
      >
        <FormControl
          fullWidth
          sx={{
            "& > label": {
              top: 23,
              left: 0,
              color: "#697586",
              '&[data-shrink="false"]': {
                top: -6,
              },
            },
          }}
        >
          {!selectedPromotion && (
            <InputLabel htmlFor="select-promotion">Select Promotion</InputLabel>
          )}
          <Select
            id="select-promotion"
            size="small"
            fullWidth
            value={selectedPromotion}
            onChange={(event) => setSelectedPromotion(event.target.value)}
          >
            <ListSubheader
              sx={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              Adjustments
            </ListSubheader>
            {promotions
              .filter((promotion) =>
                ["Rounding", "Adjustment"].includes(promotion.promoCode)
              )
              .map((promotion, index) => (
                <MenuItem key={index} value={promotion.promotionId}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Typography
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                    >
                      {promotion.promoCode}
                    </Typography>
                    <Stack direction={"row"}>
                      <Box
                        display={"flex"}
                        bgcolor={"white"}
                        width={"80px"}
                        justifyContent={"end"}
                        alignItems={"center"}
                        paddingRight={1}
                      >
                        <Typography variant="h4" color={"black"}>
                          {promotion.details.includes("%")
                            ? promotion.details
                            : "RM" + promotion.details}
                        </Typography>
                      </Box>
                      {promotion.stackable ? (
                        <LayersIcon sx={{ marginLeft: "20px" }} />
                      ) : (
                        <LayersClearIcon sx={{ marginLeft: "20px" }} />
                      )}
                    </Stack>
                  </Stack>
                </MenuItem>
              ))}
            <ListSubheader
              sx={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              General
            </ListSubheader>
            {promotions
              .filter(
                (promotion) =>
                  promotion.seats > 1 &&
                  new Date(promotion.promoEndDatetime) > new Date() &&
                  !["Rounding", "Adjustment"].includes(promotion.promoCode)
              )
              .map((promotion, index) => (
                <MenuItem key={index} value={promotion.promotionId}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Typography
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                    >
                      {promotion.promoCode}
                    </Typography>
                    <Stack direction={"row"}>
                      <Box
                        display={"flex"}
                        bgcolor={"white"}
                        width={"80px"}
                        justifyContent={"end"}
                        alignItems={"center"}
                        paddingRight={1}
                      >
                        <Typography variant="h4" color={"black"}>
                          {promotion.details.includes("%")
                            ? promotion.details
                            : "RM" + promotion.details}
                        </Typography>
                      </Box>
                      {promotion.stackable ? (
                        <LayersIcon sx={{ marginLeft: "20px" }} />
                      ) : (
                        <LayersClearIcon sx={{ marginLeft: "20px" }} />
                      )}
                    </Stack>
                  </Stack>
                </MenuItem>
              ))}
            {/* <ListSubheader
              sx={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              Individual
            </ListSubheader>
            {individualPromotions
              .filter(
                (promotion) =>
                  promotion.seats > 1 &&
                  new Date(promotion.promoEndDatetime) > new Date()
              )
              .map((promotion, index) => (
                <MenuItem key={index} value={promotion.promotionId}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Typography
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                    >
                      {promotion.promoCode}
                    </Typography>
                    <Stack direction={"row"}>
                      <Box
                        display={"flex"}
                        bgcolor={"white"}
                        width={"80px"}
                        justifyContent={"end"}
                        alignItems={"center"}
                        paddingRight={1}
                      >
                        <Typography variant="h4" color={"black"}>
                          {promotion.details.includes("%")
                            ? promotion.details
                            : "RM" + promotion.details}
                        </Typography>
                      </Box>
                      {promotion.stackable ? (
                        <LayersIcon sx={{ marginLeft: "20px" }} />
                      ) : (
                        <LayersClearIcon sx={{ marginLeft: "20px" }} />
                      )}
                    </Stack>
                  </Stack>
                </MenuItem>
              ))} */}
          </Select>
        </FormControl>
        <Button
          variant={"outlined"}
          sx={{ width: "80px" }}
          onClick={() => addPromotion()}
          disabled={!selectedPromotion || fetchLoading}
        >
          Apply
        </Button>
      </Box>
      <Menu
        id={"basic-menu"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMoreMenu}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "0",
            width: "10rem",
            border: `3px solid ${theme.palette.primary.main}`,
            backgroundColor: "black",
          },
          "& .MuiPaper-root .MuiList-root": {
            backgroundColor: "black",
            padding: "0",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            Notification.warning(
              "Please void instead as the payment has not been made"
            );
          }}
          sx={{
            height: "2rem",
            borderBottom: `3px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            backgroundColor: "black",
            fontWeight: 600,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              color: "black",
            },
          }}
        >
          Refund
        </MenuItem>
        <MenuItem
          onClick={handleAuthModalOpen}
          sx={{
            height: "2rem",
            color: theme.palette.primary.main,
            backgroundColor: "black",
            fontWeight: 600,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              color: "black",
            },
          }}
        >
          Void
        </MenuItem>
      </Menu>
      <AuthModal
        authenticationModalOpen={authModalOpen}
        handleAuthenticationModalOnClose={handleAuthModalClose}
        functionToRun={handleVoidPOA}
        requireOtherAdmin={true}
      />
      <AdjustmentModal
        open={openAdjustmentModal}
        handleClose={handleAdjustmentModalClose}
        addPromotion={addPromotion}
      />
    </Box>
  );
};

export default PaymentBox;
