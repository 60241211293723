import { format, isValid, parseISO } from "date-fns";
import { IPaymentItem } from "../models/payment/PaymentInterface";
import { BookingStatus } from "../pages/booking/interface";

const isBoolean = (string: string): boolean =>
  ["true", "false"].includes(string);

const isUUID = (string: string): boolean => {
  const uuidRegex =
    "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$";
  return new RegExp(uuidRegex).test(string);
};

const isHexColor = (string: string): boolean => {
  const hexColorRegex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";
  return new RegExp(hexColorRegex).test(string);
};

export function convertToCapitalFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase();
}

export function convertToReadableFormat(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function filterTableData(arr: any, value: string) {
  const lowercaseValue = value.toLowerCase();

  return arr.filter((obj: any) => {
    for (let key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        !isBoolean(String(obj[key])) &&
        !isUUID(String(obj[key])) &&
        !isHexColor(String(obj[key])) &&
        String(obj[key]).toLowerCase().includes(lowercaseValue)
      ) {
        return true;
      }
    }
    return false;
  });
}

export const convertKeyFromSnakeToCamel = (obj: unknown): any => {
  if (Array.isArray(obj)) {
    return obj.map((el: unknown) => convertKeyFromSnakeToCamel(el));
  } else if (typeof obj === "function" || obj !== Object(obj)) {
    return obj;
  }
  return Object.fromEntries(
    Object.entries(obj as Record<string, unknown>).map(
      ([key, value]: [string, unknown]) => [
        key.replace(/([-_][a-z])/gi, (c) =>
          c.toUpperCase().replace(/[-_]/g, "")
        ),
        convertKeyFromSnakeToCamel(value),
      ]
    )
  );
};

export const convertEachWordToCapital = (texts: string): string => {
  return texts.replace(
    /\w\S*/g,
    (text: string) =>
      text.charAt(0).toUpperCase() + text.slice(1, text.length).toLowerCase()
  );
};

export function calculateTimeDifferenceInHours(
  date1: string,
  date2: string
): number {
  const date1Formatted = new Date(date1);
  const date2Formatted = new Date(date2);
  const timeDifferenceMilliseconds =
    date2Formatted.getTime() - date1Formatted.getTime();
  const timeDifferenceHours = Math.round(
    timeDifferenceMilliseconds / (1000 * 60 * 60)
  );
  return timeDifferenceHours;
}

export const handleCheckIsBooking = (
  items: IPaymentItem[],
  validRoomTypes: string[]
) => {
  return items.some(
    (item) =>
      validRoomTypes.includes(item.itemType) ||
      item.itemType.includes("hrs Extend" || item.itemType === "Add 1 hour")
  );
};

export const convertToEpoch = (dateObj: Date) => {
  // Convert the date to ISO 8601 format
  const epochMiliseconds = dateObj.getTime();

  // Remove milliseconds to match Django's datetime format
  const djangoCompatibleStrng = Math.floor(epochMiliseconds / 1000);

  return djangoCompatibleStrng;
};

export function calculateAge(birthdate: Date) {
  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthdate.getFullYear();

  // Check if the birthday has occurred this year
  if (
    currentDate.getMonth() < birthdate.getMonth() ||
    (currentDate.getMonth() === birthdate.getMonth() &&
      currentDate.getDate() < birthdate.getDate())
  ) {
    age--;
  }

  return age;
}

export function checkUnderAge(dateString: string, component: string) {
  let day = 0;
  let month = 0;
  let year = 0;

  if (component === "scan") {
    day = Number(dateString.substring(0, 2));
    month = Number(dateString.substring(3, 5));
    year = Number(dateString.substring(6, 8));
  }
  if (component === "guestForm") {
    day = Number(dateString.substring(4, 6));
    month = Number(dateString.substring(2, 4)) - 1;
    year = Number(dateString.substring(0, 2));
  }

  const currentYear = new Date().getFullYear();

  const baseYear = year > currentYear % 100 ? 1900 : 2000;

  const birthDate = new Date(baseYear + year, month, day);

  return calculateAge(birthDate) < 18;
}

export function stringDateToEpoch(dateString: string) {
  // Create a Date object from the string
  const dateObject = new Date(dateString);

  // Check if the date is valid
  if (isNaN(dateObject.getTime())) {
    console.error("Invalid date string");
    return null;
  }

  // Convert to epoch time (seconds since January 1, 1970)
  const epochTime = dateObject.getTime() / 1000;

  return epochTime;
}

//To convert scanned passport data to JSON
function convertStringToJson(inputString: string): { [key: string]: string } {
  const lines = inputString.trim().split("\n");
  const result: { [key: string]: string } = {};

  for (let i = 1; i < lines.length - 1; i++) {
    const [key, value] = lines[i].split(":").map((item) => item.trim());
    result[key.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")] = value || "";
  }

  return result;
}

export function camelToNormal(camelStr: string): string {
  let normalStr = "";
  for (let i = 0; i < camelStr.length; i++) {
    let char = camelStr[i];
    if (char === char.toUpperCase()) {
      normalStr += " " + char;
    } else {
      normalStr += char;
    }
  }
  return normalStr.charAt(0).toUpperCase() + normalStr.slice(1);
}

export function formatToPresentableNumber(amount: string): string {
  const formattedAmount = new Intl.NumberFormat("en-MY", {
    style: "currency",
    currency: "MYR",
  }).format(parseFloat(amount));
  return formattedAmount;
}

export function formatDocumentDateFormat(date: Date | undefined) {
  if (date) {
    return format(date, "ddMMyyyy");
  } else {
    return "";
  }
}

export function extractNumbersFromString(input: string): number[] {
  const regex = /\d+(\.\d+)?/g;
  const matches = input.match(regex);
  if (matches) {
    return matches.map((match) => parseFloat(match));
  } else {
    return [];
  }
}

export const getDuration = (bookingStatus: BookingStatus[]) => {
  const lastBookingStatus = bookingStatus[bookingStatus.length - 1];
  const checkInDate = new Date(lastBookingStatus.checkInDatetime);
  const checkOutDate = new Date(lastBookingStatus.checkOutDatetime);
  const timeDifferenceMili = checkOutDate.getTime() - checkInDate.getTime();

  const hoursDifference = timeDifferenceMili / (1000 * 60 * 60);

  return hoursDifference;
};

export function consolidateItems(items: IPaymentItem[]) {
  const consolidatedItems: { [key: string]: IPaymentItem } = {};

  // Iterate through each item
  items.forEach((item) => {
    const itemName = item.itemName;

    // If the item name already exists in consolidatedItems, add its quantity
    if (consolidatedItems[itemName]) {
      consolidatedItems[itemName].quantity! += item.quantity!;
    } else {
      // Otherwise, create a new entry in consolidatedItems
      consolidatedItems[itemName] = {
        category: item.category,
        duration: item.duration,
        itemId: item.itemId,
        itemName: itemName,
        itemType: item.itemType,
        price: item.price,
        quantity: item.quantity,
      };
    }
  });

  // Convert the object back to an array
  const consolidatedArray = Object.values(consolidatedItems);

  return consolidatedArray;
}

export const toNumberString = (value: string) => value.replace(/[^\d-]/g, "");

export function isValidDate(dateString: string): boolean {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date.getTime());
}

export function getYesterdayAndTomorrow() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();

  let yesterday, tomorrow;

  // Calculate yesterday
  if (day === 1) {
    // Today is the first day of the month
    yesterday = new Date(year, month - 1, 1); // Last day of the previous month
  } else {
    // Any other day
    yesterday = new Date(year, month, 1);
  }

  console.log(yesterday);

  // Calculate tomorrow
  const lastDayOfCurrentMonth = new Date(year, month + 1, 0).getDate();
  if (day === lastDayOfCurrentMonth) {
    tomorrow = new Date(year, month + 2, 0);
  } else {
    // Any other day
    tomorrow = new Date(year, month, day + 1);
  }

  // Convert to ISO string format
  const yesterdayISO = yesterday.toISOString();
  const tomorrowISO = tomorrow.toISOString();

  return { yesterday: yesterdayISO, tomorrow: tomorrowISO };
}
