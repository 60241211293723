import { Grid, Typography, TextField, Stack, Box } from "@mui/material";
import React from "react";
import theme from "../../assets/theme/theme";

type Props = {
  label: string;
  value: string | number;
  name: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  textFieldType?: string;
  lastChild?: boolean;
  placeholder?: string;
};

const ManagementModalFormTextField = ({
  label,
  value,
  name,
  handleChange,
  handleBlur,
  startAdornment,
  endAdornment,
  textFieldType = "text",
  lastChild = false,
  placeholder,
}: Props) => {
  return (
    <Stack direction={"row"} width={"100%"}>
      <Box
        display={"flex"}
        border={2}
        borderColor={theme.palette.primary.main}
        width={"230px"}
        alignItems={"center"}
        paddingX={1}
      >
        <Typography variant={"h4"} fontWeight={600}>
          {label}
        </Typography>
      </Box>
      <Box border={2} borderColor={theme.palette.primary.main} width={"100%"}>
        <TextField
          required
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          type={textFieldType}
          fullWidth
          sx={{
            border: "none",
          }}
          InputProps={{
            sx: {
              height: "3em",
              padding: "0",
              backgroundColor: theme.palette.background.default,
              border: "none",
              borderRadius: "0",
              "& .MuiOutlinedInput-notchedOutline": {
                borderLeft: "none",
                borderBottom: lastChild
                  ? `3px solid ${theme.palette.primary.main}`
                  : "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: `3px solid ${theme.palette.primary.main}`,
                borderLeft: "none",
                borderBottom: lastChild
                  ? `3px solid ${theme.palette.primary.main}`
                  : "none",
              },
              ...(textFieldType === "number" && {
                "& .MuiInputBase-input.MuiOutlinedInput-input": {
                  MozAppearance: "textfield",
                  appearance: "textfield",
                  margin: 0,
                },
                "& .MuiInputBase-input.MuiOutlinedInput-input::-webkit-inner-spin-button, & .MuiInputBase-input.MuiOutlinedInput-input::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
              }),
            },
            startAdornment: startAdornment || null,
            endAdornment: endAdornment || null,
          }}
          inputProps={{
            style: {
              height: "3em",
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: startAdornment ? "0.5em" : "1.5em",
              paddingRight: endAdornment ? "0.5em" : "1.5em",
              border: "none",
              borderRadius: "0",
              fontWeight: 600,
            },
            placeholder: placeholder || "",
          }}
        />
      </Box>
    </Stack>
  );
};

export default ManagementModalFormTextField;
