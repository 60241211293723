import {
  Box,
  Button,
  ButtonBase,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import theme from "../../assets/theme/theme";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom";
import CustomTable from "../../components/global/table/CustomTable";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import axiosInstance from "../../constants/axiosConfig";
import { NewAPIBookingResult } from "./../booking/interface";
import { store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import { useNavigate, useParams } from "react-router-dom";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { format } from "date-fns";
import { convertToEpoch, filterTableData } from "../../utils/functions";
import useUpdate from "../../hooks/useUpdate";

type Props = {
  tableStatus: string;
};

type CheckinDataItem = {
  id: number;
  bookingId: string;
  bookingNumber: string;
  customer: string;
  platform: string;
  status: string;
  checkInTime: string;
  checkOutTime: string;
  actualCheckinDatetime: string | null;
  actualCheckoutDatetime: string | null;
  roomCode: string[];
  roomTypes: string[];
  bookingMadeDatetime: string | null | undefined;
  overstayDuration: string;
  isOverstay: boolean;
};

function GuestTrackingBookings() {
  const { customerId } = useParams();
  const today = new Date();
  const minus1Date = new Date(new Date().getFullYear(), 0, 1);

  const dayAfter = new Date(today);
  dayAfter.setDate(today.getDate() + 1);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [checkInData, setCheckInData] = useState<CheckinDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortByValue, setSortByValue] = useState("Time DSC");
  const [bookStatusFilter, setBookStatusFilter] = useState("All");
  const [bookPlatformFilter, setBookPlatformFilter] = useState("All");
  const [bookStatusValue, setBookStatusValue] = useState<string[]>([]);
  const [bookPlatformValue, setBookPlatformValue] = useState<string[]>([]);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [displayTableData, setDisplayTableData] = useState<CheckinDataItem[]>(
    []
  );

  const [startTimeValue, setStartTimeValue] = useState<Date>(minus1Date);
  const [endTimeValue, setEndTimeValue] = useState<Date>(dayAfter);

  const token = store.getState().user.accessToken;

  const navigate = useNavigate();

  const { updateExpiredBookingStatus } = useUpdate();

  const handleNavigateOnClick = (bookingId: any) => {
    navigate(`${bookingId}`);
  };

  const sorting = [{ id: "checkInTime", desc: true }];

  const checkInColumns = useMemo<MRT_ColumnDef<CheckinDataItem>[]>(
    () => [
      {
        accessorKey: "bookingNumber",
        header: "Booking No.",
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => handleNavigateOnClick(row.original.bookingId)}
              sx={{
                background: "black",
                border: 1,
                borderColor: theme.palette.primary.main,
              }}
            >
              {row.original.bookingNumber}
            </Button>
          );
        },
      },
      {
        header: "Booking Date",
        accessorKey: "bookingMadeDatetime",
      },
      {
        accessorKey: "checkInTime",
        header: "Check-In",
      },
      {
        header: "Check-Out",
        accessorKey: "checkOutTime",
      },
      {
        accessorKey: "customer",
        header: "Customer",
      },
      {
        header: "Room Types",
        accessorKey: "roomTypes",
      },
      {
        accessorKey: "platform",
        header: "Booking Source",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
    ],
    []
  );

  const handleSortByValueChange = (event: any) => {
    setSortByValue(event);
  };

  const handleBookStatusFilterOnChange = (event: any) => {
    setBookStatusFilter(event);
  };

  const handleBookPlatformFilterOnChange = (event: any) => {
    setBookPlatformFilter(event);
  };

  const handleSearchBarOnChange = (event: any) => {
    setSearchBarValue(event.target.value);
  };

  const handleStartTimeValueChange = (e: Date) => {
    setStartTimeValue(e);
  };

  const handleEndTimeValueChange = (e: Date) => {
    setEndTimeValue(e);
  };

  const fetchListData = () => {
    setIsLoading(true);

    const startDate = startTimeValue;
    const endDate = endTimeValue;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);

    const startTime = convertToEpoch(startDate);
    const endTime = convertToEpoch(endDate);
    const queryParams = `startDateTime=${startTime}&endDateTime=${endTime}&customerStayingId=${customerId}`;

    axiosInstance
      .get(`/bookings/list?${queryParams}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const results: NewAPIBookingResult[] = res.data;

        const data = results.map((item, index) => {
          const checkInData: CheckinDataItem = {
            id: index,
            bookingNumber: item.bookingNo,
            bookingMadeDatetime: format(
              new Date(item.bookingMadeDatetime),
              "dd/MM/yyyy HH:mm"
            ),
            checkInTime: item.bookingStatus[0]
              ? format(
                  new Date(item.bookingStatus[0].checkInDatetime),
                  "dd/MM/yyyy HH:mm"
                )
              : "",
            checkOutTime: item.bookingStatus[0]
              ? format(
                  new Date(item.bookingStatus[0].checkOutDatetime),
                  "dd/MM/yyyy HH:mm"
                )
              : "",
            actualCheckinDatetime: item.roomBookings[0].actualCheckinDateTime,
            actualCheckoutDatetime: item.roomBookings[0].actualCheckoutDateTime,
            customer:
              item.customerStayingName === " "
                ? item.customerBooked
                : item.customerStayingName,
            platform: item.platform.platform,
            status: item.bookingStatus[0]
              ? item.bookingStatus[0].bookingStatus
              : "",
            roomCode: item.roomBookings.map((item) => item.roomCode),
            roomTypes: Array.from(
              new Set(item.roomBookings.map((item) => item.roomType))
            ),
            bookingId: item.bookingId,
            overstayDuration:
              item.overstayDuration && item.isOverstay
                ? item.overstayDuration.toString()
                : "",
            isOverstay: item.isOverstay ? item.isOverstay : false,
          };
          return checkInData;
        });
        setCheckInData(handleSortTimeDSC(data));
      })
      // setTablePaginationData(results.count);
      .catch((error) => {
        Notification.failed(error.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTableDataAssignation = () => {
    // const checkInDataFilter = checkInData.filter(
    //   (item) =>
    //     item.status === "Booked" ||
    //     item.status === "Confirm Booking" ||
    //     item.status === "No Show"
    // );
    // const checkOutDataFilter = checkInData.filter(
    //   (item) => item.status === "Check In" || item.status === "Overstayed"
    // );
    //setCheckInTableData(checkInDataFilter);
    //setCheckOutTableData(checkOutDataFilter);
  };

  useEffect(() => {
    fetchListData();
    updateExpiredBookingStatus(token);
  }, [startTimeValue, endTimeValue]);

  useEffect(() => {
    const uniqueStatus = new Set(checkInData.map((item) => item.status));
    const uniquePlatform = new Set(checkInData.map((item) => item.platform));
    const uniqueStatusArray = Array.from(uniqueStatus);
    const uniquePlatformArray = Array.from(uniquePlatform);
    setBookStatusValue(uniqueStatusArray);
    setBookPlatformValue(uniquePlatformArray);
    if (checkInData) {
      handleTableDataAssignation();
    }
  }, [checkInData]);

  const handleSortTimeASC = (data: CheckinDataItem[]): CheckinDataItem[] => {
    const sortedData = data.slice().sort((a, b) => {
      const dateA = new Date(a.checkInTime).getTime();
      const dateB = new Date(b.checkInTime).getTime();

      return dateA - dateB;
    });
    return sortedData;
  };

  const handleSortTimeDSC = (data: CheckinDataItem[]): CheckinDataItem[] => {
    const sortedData = data.slice().sort((a, b) => {
      const dateA = new Date(a.checkInTime).getTime();
      const dateB = new Date(b.checkInTime).getTime();

      return dateB - dateA;
    });
    return sortedData;
  };

  const handleSortBookingNumASC = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let sortedData = data.slice();
    const charToRemove = "CKLIA-";
    sortedData.sort((a, b) => {
      const aStripString = a.bookingNumber.replace(charToRemove, "");
      const bStripString = b.bookingNumber.replace(charToRemove, "");
      const numberedA = Number(aStripString);
      const numberedB = Number(bStripString);
      return numberedA - numberedB;
    });
    return sortedData;
  };

  const handleSortBookingNumDSC = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let sortedData = [...data];
    const charToRemove = "CKLIA-";
    sortedData.sort((a, b) => {
      const aStripString = a.bookingNumber.replace(charToRemove, "");
      const bStripString = b.bookingNumber.replace(charToRemove, "");
      const numberedA = Number(aStripString);
      const numberedB = Number(bStripString);
      return numberedB - numberedA;
    });
    return sortedData;
  };

  const handleSortBy = (data: CheckinDataItem[]): CheckinDataItem[] => {
    let newData: CheckinDataItem[] = [];
    if (sortByValue === "All") {
      newData = [...data];
    } else if (sortByValue === "Time ASC") {
      newData = handleSortTimeASC(data);
    } else if (sortByValue === "Time DSC") {
      newData = handleSortTimeDSC(data);
    } else if (sortByValue === "Booking Number ASC") {
      newData = handleSortBookingNumASC(data);
    } else if (sortByValue === "Booking Number DSC") {
      newData = handleSortBookingNumDSC(data);
    } else if (sortByValue === "Overstay") {
    }
    return newData;
  };

  const handleBookStatusFilter = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let newData: CheckinDataItem[] = [];
    if (bookStatusFilter === "All") {
      newData = [...data];
    } else {
      bookStatusValue.map((item) => {
        if (bookStatusFilter === item) {
          newData = data.filter((item2) => {
            return item2.status === item;
          });
        }
      });
    }
    return newData;
  };

  const handleBookPlatformFilter = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let newData: CheckinDataItem[] = [];
    if (bookPlatformFilter === "All") {
      newData = [...data];
    } else {
      bookPlatformValue.map((item) => {
        if (bookPlatformFilter === item) {
          newData = data.filter((item2) => {
            return item2.platform === item;
          });
        }
      });
    }
    return newData;
  };

  const handleAllFilter = () => {
    const rawUnsortedData = checkInData;
    const sortedData = handleSortBy(rawUnsortedData);
    const filteredBookStatusData = handleBookStatusFilter(sortedData);
    const filteredBookPlatformData = handleBookPlatformFilter(
      filteredBookStatusData
    );
    setCheckInData(filteredBookPlatformData);
    return filteredBookPlatformData;
  };

  useEffect(() => {
    handleAllFilter();
  }, [sortByValue, bookStatusFilter, bookPlatformFilter]);

  return (
    <Box
      width={"85%"}
      border={1}
      borderColor={theme.palette.primary.main}
      margin={"auto"}
    >
      <Stack
        paddingY={1}
        paddingX={3}
        borderBottom={2}
        borderColor={theme.palette.primary.main}
        sx={{ background: "#232323" }}
      >
        <Typography variant={"h3"} color={theme.palette.primary.main}>
          Guest Tracking
        </Typography>
      </Stack>
      <Grid container borderBottom={2} borderColor={theme.palette.primary.main}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            paddingY={4}
            paddingX={10}
            borderRight={1}
            borderColor={theme.palette.primary.main}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            // sx={{ background: "white" }}
          >
            <DatePicker
              value={startTimeValue}
              onChange={(e) => handleStartTimeValueChange(e as Date)}
              slotProps={{
                layout: {
                  sx: {
                    ".MuiDateCalendar-root": {
                      color: theme.palette.primary.main,
                      borderRadius: 2,
                      borderWidth: 1,
                      borderColor: theme.palette.primary.main,
                      border: "1px solid",
                      backgroundColor: theme.palette.background.paper,
                    },
                    ".MuiButtonBase-root": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiTypography-root": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiPickersCalendarHeader-root": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiPickersMonth-monthButton": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiPickersCalendarHeader-label": {
                      color: theme.palette.primary.main,
                    },
                    ".Mui-selected": {
                      color: "black",
                    },
                    ".MuiPickersYear-yearButton": {
                      color: theme.palette.primary.main,
                    },
                  },
                },
                textField: {
                  InputProps: {
                    style: {
                      background: "#232323",
                    },
                  },
                  sx: {
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      width: "20em",
                      padding: "0",
                      borderRadius: "1em",
                    },
                  },
                },
                openPickerIcon: {
                  sx: {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            />
            <ArrowRightAltIcon fontSize={"large"} />
            <DatePicker
              value={endTimeValue}
              onChange={(e) => handleEndTimeValueChange(e as Date)}
              slotProps={{
                layout: {
                  sx: {
                    ".MuiDateCalendar-root": {
                      color: theme.palette.primary.main,
                      borderRadius: 2,
                      borderWidth: 1,
                      borderColor: theme.palette.primary.main,
                      border: "1px solid",
                      backgroundColor: theme.palette.background.paper,
                    },
                    ".MuiButtonBase-root": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiTypography-root": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiPickersCalendarHeader-root": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiPickersMonth-monthButton": {
                      color: theme.palette.primary.main,
                    },
                    ".MuiPickersCalendarHeader-label": {
                      color: theme.palette.primary.main,
                    },
                    ".Mui-selected": {
                      color: "black",
                    },
                    ".MuiPickersYear-yearButton": {
                      color: theme.palette.primary.main,
                    },
                  },
                },
                textField: {
                  InputProps: {
                    style: {
                      background: "#232323",
                    },
                  },
                  sx: {
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      width: "20em",
                      padding: "0",
                      borderRadius: "1em",
                    },
                  },
                },
                openPickerIcon: {
                  sx: {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            />
          </Grid>
        </LocalizationProvider>
        <Grid item md={4} sm={12} xs={12}>
          <ButtonBase
            onClick={fetchListData}
            sx={{
              width: "100%",
              height: "100%",
              background: "#232323",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant={"h3"}>Change Date</Typography>
          </ButtonBase>
        </Grid>
      </Grid>
      <Stack borderBottom={2} borderColor={theme.palette.primary.main}>
        <Grid container padding={2} spacing={2} justifyContent={"center"}>
          <Grid item md={10} sm={12} xs={12}>
            <TextField
              type={"search"}
              size={"small"}
              onChange={(e) => {
                // const newRow = filterTableData(checkInData, e.target.value);
                // setDisplayTableData(newRow);
                handleSearchBarOnChange(e);
              }}
              sx={{
                width: "100%",
                borderRadius: 0,
                border: 1,
                borderColor: theme.palette.primary.main,
              }}
            />
          </Grid>
          {/* <Grid item md={1} sm={12} xs={12}>
              <Button
                // onClick={handleSearchTableData}
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 0,
                  border: 1,
                  borderColor: theme.palette.primary.main,
                  background: "#232323",
                }}
              >
                <Typography color={"white"} fontWeight={700}>
                  Search
                </Typography>
              </Button>
            </Grid> */}
        </Grid>
      </Stack>
      <Stack>
        <Grid
          container
          borderBottom={2}
          borderColor={theme.palette.primary.main}
          minHeight={"5.9em"}
        >
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            display={"flex"}
            flexDirection={"row"}
          >
            <Stack
              width={"30%"}
              borderRight={2}
              padding={3}
              justifyContent={"center"}
              alignItems={"center"}
              borderColor={theme.palette.primary.main}
            >
              Sorter
            </Stack>
            <Stack
              width={"70%"}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={"50%"}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={"center"}
                paddingLeft={2}
              >
                <Typography>Sort By</Typography>
              </Stack>
              <Stack height={"50%"}>
                <Select
                  size={"small"}
                  value={sortByValue}
                  onChange={(e) => handleSortByValueChange(e.target.value)}
                >
                  <MenuItem value={"All"}>None</MenuItem>
                  <MenuItem value={"Time ASC"}>
                    {checkInData ? "Check-In ASC" : "Check-Out ASC"}
                  </MenuItem>
                  <MenuItem value={"Time DSC"}>
                    {checkInData ? "Check-In DSC" : "Check-Out DSC"}
                  </MenuItem>
                  <MenuItem value={"Booking Number ASC"}>
                    Booking No. ASC
                  </MenuItem>
                  <MenuItem value={"Booking Number DSC"}>
                    Booking No. DSC
                  </MenuItem>
                  <MenuItem value={"Overstay"}>Overstay</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            display={"flex"}
            flexDirection={"row"}
          >
            <Stack
              width={"30%"}
              borderRight={2}
              padding={3}
              justifyContent={"center"}
              alignItems={"center"}
              borderColor={theme.palette.primary.main}
            >
              <Typography>Filter</Typography>
            </Stack>
            <Stack
              width={"35%"}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={"50%"}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={"center"}
                paddingLeft={2}
              >
                <Typography>Book Status</Typography>
              </Stack>
              <Stack height={"50%"}>
                <Select
                  size={"small"}
                  value={bookStatusFilter}
                  onChange={(e) =>
                    handleBookStatusFilterOnChange(e.target.value)
                  }
                >
                  <MenuItem value={"All"}>None</MenuItem>
                  {bookStatusValue.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Stack>
            <Stack
              width={"35%"}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={"50%"}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={"center"}
                paddingLeft={2}
              >
                <Typography>Book Platform</Typography>
              </Stack>
              <Stack height={"50%"}>
                <Select
                  size={"small"}
                  value={bookPlatformFilter}
                  onChange={(e) =>
                    handleBookPlatformFilterOnChange(e.target.value)
                  }
                >
                  <MenuItem value={"All"}>None</MenuItem>
                  {bookPlatformValue.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <CustomTable
        columns={checkInColumns}
        data={checkInData}
        isLoading={isLoading}
        handlePaginationChange={setPagination}
        pagination={pagination}
        rowCount={checkInData.length}
        oddEvenBackground={true}
        globalFilter={searchBarValue}
      />
    </Box>
  );
}

export default GuestTrackingBookings;
