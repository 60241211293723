import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ManagementModalHeader from "../management/ManagementModalHeader";
import { DocumentScanner, PeopleAlt, PersonAdd } from "@mui/icons-material";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MRT_ColumnDef } from "material-react-table";
import {
  IEditGuestInfo,
  IExistingCustomerRow,
  IPOSCustomerStaying,
} from "../../models/guests/GuestsInterface";
import axiosInstance from "../../constants/axiosConfig";
import { RootState, store } from "../../redux/store";
import Notification from "../../utils/notificationConfig";
import CustomTable from "../global/table/CustomTable";
import AddGuestForm from "./AddGuestForm";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { checkUnderAge } from "../../utils/functions";
import { convertScannedDataToIGuestInfo } from "../../utils/convertScannedDatatoIGuestInfo";
import { useDebounce } from "../../hooks/useDebounce";
import { useSelector } from "react-redux";

const GuestAddAssignModal = (props: {
  open: boolean;
  existingName?: string;
  handleClose: VoidFunction;
  handleSetCustomerStaying: (guest: IExistingCustomerRow) => void;
}) => {
  const theme = useTheme();
  const [selectedStep, setSelectedStep] = useState<number>(0);
  const [addGuestScanned, setAddGuestScanned] = useState<IEditGuestInfo>();

  const handleScanNotFound = () => {
    setSelectedStep(2);
  };

  const handleSetGuestScanned = (data: IEditGuestInfo) => {
    setAddGuestScanned(data);
  };

  useEffect(
    () => handleSetGuestScanned(initialEditGuestInfo),
    [props.handleClose]
  );
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <Box
        border={`3px solid ${theme.palette.primary.main}`}
        alignItems={"center"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
          borderRadius: 1,
          minWidth: "900px",
        }}
      >
        <ManagementModalHeader
          title={"Assign Guest"}
          handleModalClose={props.handleClose}
        />
        <Stack
          flexDirection={"row"}
          width={"100%"}
          borderBottom={2}
          borderColor={theme.palette.primary.main}
        >
          <ButtonBase onClick={() => setSelectedStep(0)} sx={{ width: "50%" }}>
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"row"}
              height={"80px"}
              paddingX={2}
              alignItems={"center"}
              bgcolor={
                selectedStep === 0
                  ? theme.palette.primary.main
                  : theme.palette.background.default
              }
              color={selectedStep === 0 ? "black" : "white"}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <PeopleAlt sx={{ marginRight: 2, fontSize: "32px" }} />
              <Typography
                variant="h3"
                color={selectedStep === 0 ? "black" : "white"}
              >
                Scan Document
              </Typography>
            </Box>
          </ButtonBase>
          <ButtonBase onClick={() => setSelectedStep(1)} sx={{ width: "50%" }}>
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"row"}
              height={"80px"}
              paddingX={2}
              alignItems={"center"}
              bgcolor={
                selectedStep === 1
                  ? theme.palette.primary.main
                  : theme.palette.background.default
              }
              color={selectedStep === 1 ? "black" : "white"}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <PersonAdd sx={{ marginRight: 2, fontSize: "32px" }} />
              <Typography
                variant="h3"
                color={selectedStep === 1 ? "black" : "white"}
              >
                Existing Guest
              </Typography>
            </Box>
          </ButtonBase>
          <ButtonBase onClick={() => setSelectedStep(2)} sx={{ width: "50%" }}>
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"row"}
              height={"80px"}
              paddingX={2}
              alignItems={"center"}
              bgcolor={
                selectedStep === 2
                  ? theme.palette.primary.main
                  : theme.palette.background.default
              }
              color={selectedStep === 2 ? "black" : "white"}
            >
              <DocumentScanner sx={{ marginRight: 2, fontSize: "32px" }} />
              <Typography
                variant="h3"
                color={selectedStep === 2 ? "black" : "white"}
              >
                New Guest
              </Typography>
            </Box>
          </ButtonBase>
        </Stack>
        {selectedStep === 0 ? (
          <ScanDocument
            handleSetCustomerStaying={props.handleSetCustomerStaying}
            handleClose={props.handleClose}
            handleSetGuestScanned={handleSetGuestScanned}
            handleOpenAddGuestForm={handleScanNotFound}
            handleStep={setSelectedStep}
          />
        ) : selectedStep === 1 ? (
          <ExistingCustomerTable
            handleSetCustomerStaying={props.handleSetCustomerStaying}
            handleClose={props.handleClose}
          />
        ) : (
          <AddGuestForm
            handleSetCustomerStaying={props.handleSetCustomerStaying}
            handleClose={props.handleClose}
            existingName={props.existingName}
            scannedData={addGuestScanned ? addGuestScanned : undefined}
            handleSetGuestScanned={handleSetGuestScanned}
          />
        )}
      </Box>
    </Modal>
  );
};

const ExistingCustomerTable = (props: {
  handleClose: VoidFunction;
  handleSetCustomerStaying: (guest: IExistingCustomerRow) => void;
}) => {
  const theme = useTheme();
  const [pagination, setPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({ pageIndex: 0, pageSize: 7 });

  const [existingCustomerRows, setExistingCustomerRows] = useState<
    IExistingCustomerRow[]
  >([]);

  const [searchValue, setSearchValue] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchExistingCustomerRows = async () => {
    const token = store.getState().user.accessToken;

    setIsLoading(true);

    axiosInstance
      .get(`/guests/search`, {
        params: {
          value: searchValue,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: any) => {
        const existingCustomerRows = data.map((row: any) => {
          return {
            customerId: row.customerId,
            fullName: row.firstname + " " + row.lastname,
            icNo: row.idNo,
            memberId: row.member.memberId,
            memberTier: row.member.memberTier,
            memberCondition: row.member.memberCondition,
            countryCode: row.country.countryName,
            isEmptyRow: false,
            phone: row.phoneNumber,
            email: row.email,
            rententionCount: row.rententionCount,
          };
        });

        setExistingCustomerRows([...existingCustomerRows]);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchValue) {
        fetchExistingCustomerRows();
      }
    }, 1000); // 3000 milliseconds = 3 seconds
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  const columns = useMemo<MRT_ColumnDef<IExistingCustomerRow>[]>(
    () => [
      {
        accessorKey: "customerId",
        header: "Customer ID",
        size: 250,
      },
      {
        accessorKey: "fullName",
        header: "Name",
        size: 150,
        filterVariant: "text",
      },
      {
        accessorKey: "icNo",
        header: "IC No./Passport No.",
        size: 150,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        Header: () => (
          <Typography
            paddingX={"8px"}
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
            }}
          >
            {"Action"}
          </Typography>
        ),
        accessorKey: "isEmptyRow",
        size: 100,
        Cell: ({ row }) =>
          row.original.isEmptyRow ? (
            <Button
              disabled
              sx={{
                opacity: 0,
              }}
            >
              .
            </Button>
          ) : (
            <Button
              onClick={() => {
                props.handleSetCustomerStaying(row.original);
              }}
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "semi-bold",
              }}
            >
              Assign
            </Button>
          ),
      },
    ],
    []
  );

  return (
    <>
      <Box
        display={"flex"}
        sx={{
          alignItems: "center",
          padding: 1,
          bgcolor: theme.palette.background.paper,
        }}
      >
        <TextField
          type={"search"}
          size={"small"}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          sx={{
            width: "100%",
            borderRadius: 1,
            border: 1,
            borderColor: theme.palette.primary.main,
          }}
        />
      </Box>
      <CustomTable
        columns={columns}
        data={existingCustomerRows}
        pagination={pagination}
        handlePaginationChange={setPagination}
        isLoading={isLoading}
        showColumnFilters={true}
        enableTopToolbar={false}
        oddEvenBackground={true}
      />
    </>
  );
};
const initialConvertedData: IExistingCustomerRow = {
  customerId: "",
  fullName: "",
  icNo: "",
  memberId: "",
  memberTier: "",
  memberCondition: "",
  countryCode: "",
  phone: "",
  email: "",
  idType: {
    idTypeId: "",
    idType: "",
  },
  rententionCount: 0,
};
const initialEditGuestInfo: IEditGuestInfo = {
  id: "",
  firstname: "",
  lastname: "",
  gender: "",
  idType: "",
  id_no: "",
  phoneNumber: "",
  email: "",
  country: "",
  member: "",
  idTypeName: "",
  phonePrefix: "",
};
const ScanDocument = (props: {
  handleStep: Dispatch<SetStateAction<number>>;
  handleClose: VoidFunction;
  handleSetCustomerStaying: (guest: IExistingCustomerRow) => void;
  handleSetGuestScanned: (data: IEditGuestInfo) => void;
  handleOpenAddGuestForm: VoidFunction;
}) => {
  const token = useSelector((state: RootState) => state.user.accessToken);
  const [inputField, setInputField] = useState<string>("");

  const debouncedValue = useDebounce(inputField, 500);

  const handleIdInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputField(event.target.value);
  };

  const scannedData = convertScannedDataToIGuestInfo(debouncedValue);

  useEffect(() => {
    if (debouncedValue !== "" && scannedData.idNumber !== "") {
      if (
        scannedData.dateOfBirth &&
        checkUnderAge(scannedData.dateOfBirth, "scan")
      ) {
        Notification.failed("Guest assigned is below 18 years old");
        return;
      }

      axiosInstance
        .get(`/guests/get_customer_by_id_no/?id_no=${scannedData.idNumber}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          const convertedData: IExistingCustomerRow = {
            countryCode: data.country.countryId,
            customerId: data.customerId,
            fullName: data.firstname + " " + data.lastname,
            icNo: data.idNo,
            memberId: data.member.memberId,
            memberCondition: data.member.memberCondition,
            memberTier: data.member.memberTier,
            email: data.member.email,
            idType: data.idType.idTypeId,
            phone: data.phoneNumber,
            rententionCount: data.rententionCount,
          };
          props.handleSetCustomerStaying(convertedData);
        })
        .catch((err) => {
          const convertedData: IEditGuestInfo = {
            country: scannedData.country,
            firstname: scannedData.firstName,
            lastname: scannedData.lastName,
            gender: scannedData.gender,
            id: scannedData.id,
            id_no: scannedData.idNumber,
            idType: scannedData.idType,
            idTypeName: scannedData.idTypeName,
            phoneNumber: "",
            member: "",
            email: "",
          };
          props.handleSetGuestScanned(convertedData);
          props.handleOpenAddGuestForm();
          Notification.warning("Guest Not Found, Please Add New Guest");
        });
    }
  }, [scannedData.idNumber]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width={"100%"}
    >
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        direction={"row"}
        height={"80px"}
        marginY={2}
      >
        <RssFeedIcon
          sx={{
            fontSize: "50px",
            marginRight: "10px",
          }}
        />
        <Typography variant={"h2"}>Scan IC / Passport</Typography>
      </Stack>
      <TextField
        multiline
        id={""}
        rows={8}
        autoFocus
        value={inputField}
        onChange={handleIdInputChange}
        fullWidth
        sx={{
          border: "1px solid grey",
          "& .MuiInputBase-root": {
            padding: 0,
          },
          "& .MuiOutlinedInput-root": {
            padding: 0,
          },
        }}
        inputProps={{
          style: { padding: "10px" },
        }}
        placeholder="Please do not type anything here"
      />
    </Box>
  );
};

export default GuestAddAssignModal;
