import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useEffect, useMemo, useRef, useState } from "react";
import theme from "../../assets/theme/theme";
import ManagementActionMenu from "../../components/management/ManagementActionMenu";
import ManagementContentArea from "../../components/management/ManagementContentArea";
import { IIndividualPromo } from "../../types/promotion";
import LayersIcon from "@mui/icons-material/Layers";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import Notification from "../../utils/notificationConfig";
import { store } from "../../redux/store";
import { format } from "date-fns";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import axiosInstance from "../../constants/axiosConfig";
import IndividualPromotionModal from "../../components/promotion/IndividualPromotionModal";

const milestoneFilters = ["All", "Milestone", "Non-Milestone"];

export default function Membership() {
  const [promotions, setPromotions] = useState<IIndividualPromo[]>([]);
  const [displayPromotions, setDisplayPromotions] = useState<
    IIndividualPromo[]
  >([]);
  const handlePromotionChange = (promotions: IIndividualPromo[]) => {
    setDisplayPromotions(
      promotions.sort((a, b) => {
        // Sort by archived status first (archived promotions always at the bottom)
        if (a.archived && !b.archived) return 1;
        if (!a.archived && b.archived) return -1;

        // Sort by isMilestone (if applyMilestoneSorting is true)
        if (a.isMilestone && !b.isMilestone) return -1;
        if (!a.isMilestone && b.isMilestone) return 1;

        // Sort by isActive (based on promoEndDatetime and archived status)
        const isActiveA =
          new Date(a.promoEndDatetime) > new Date() && !a.archived;
        const isActiveB =
          new Date(b.promoEndDatetime) > new Date() && !b.archived;
        if (isActiveA && !isActiveB) return -1;
        if (!isActiveA && isActiveB) return 1;

        // Sort by promoEndDatetime in descending order
        const dateA = new Date(a.promoEndDatetime);
        const dateB = new Date(b.promoEndDatetime);
        return dateB.getTime() - dateA.getTime();
      })
    );
  };

  const [excludeArchive, setExcludeArchive] = useState<boolean>(false);
  const [milestoneFilter, setMilestoneFilter] = useState<string>("All");

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 12,
  });

  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleSearchQueryChange = (search: string) => {
    setSearchQuery(search);
  };

  const fetchAllPromotions = async () => {
    await axiosInstance
      .get(`/promotions/individual-promo/list`, {
        headers: {
          Authorization: `Bearer ${store.getState().user.accessToken}`,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setPromotions(response.data.data);
          return;
        }
        Notification.warning(response.data.message);
      })
      .catch((err) => {
        Notification.failed(err.message);
      });
  };

  useEffect(() => {
    fetchAllPromotions();
  }, []);

  useEffect(() => {
    if (excludeArchive || milestoneFilter) {
      const filteredPromotion = promotions
        .filter((item) => (excludeArchive ? item.archived === false : item))
        .filter((item) => {
          if (milestoneFilter === "Milestone") {
            return item.isMilestone;
          } else if (milestoneFilter === "Non-Milestone") {
            return !item.isMilestone;
          }
          return item;
        });
      handlePromotionChange(filteredPromotion);
      return;
    }
    handlePromotionChange(promotions);
  }, [promotions, excludeArchive, milestoneFilter]);

  const actionMenuAnchorElRef = useRef<HTMLButtonElement | null>(null);

  const initialSelectedPromotionObjValues: IIndividualPromo = {
    individualPromoId: "",
    promoCode: "",
    details: "RM",
    promoStartDatetime: "",
    promoEndDatetime: "",
    seats: 0,
    stackable: false,
    archived: false,
    hoursToHit: 0,
    isMilestone: false,
    maxCapped: "",
  };

  const [selectedPromotionObj, setSelectedPromotionObj] =
    useState<IIndividualPromo>(initialSelectedPromotionObjValues);
  const handleSelectedPromotionObjChange = (promotion: IIndividualPromo) => {
    setSelectedPromotionObj(promotion);
  };

  const [isActionMenuOpen, setIsActionMenuOpen] = useState<boolean>(false);
  const handleActionMenuOpen = (clickedButtonId: string) => {
    actionMenuAnchorElRef.current = document.getElementById(
      clickedButtonId
    ) as HTMLButtonElement;
    setIsActionMenuOpen(true);
  };
  const handleActionMenuClose = () => {
    actionMenuAnchorElRef.current = null;
    setSelectedPromotionObj(initialSelectedPromotionObjValues);
    setIsActionMenuOpen(false);
  };

  const [isEditPromotionModalOpen, setIsEditPromotionModalOpen] =
    useState<boolean>(false);
  const handleEditPromotionModalOpen = () => {
    setIsEditPromotionModalOpen(true);
  };
  const handleEditPromotionModalClose = () => {
    setIsEditPromotionModalOpen(false);
    handleActionMenuClose();
  };

  const [isAddPromotionModalOpen, setIsAddPromotionModalOpen] =
    useState<boolean>(false);
  const handleAddPromotionModalOpen = () => {
    setIsAddPromotionModalOpen(true);
  };
  const handleAddPromotionModalClose = () => {
    setIsAddPromotionModalOpen(false);
  };

  const handleArchivePromotion = async () => {
    const updatedPromotion = promotions.filter(
      (promotion) =>
        promotion.individualPromoId !== selectedPromotionObj.individualPromoId
    );

    let changedSelected = selectedPromotionObj;

    changedSelected.archived = !changedSelected.archived;

    const apiUrl = "/promotions/individual-promo/edit-archive/";

    await axiosInstance
      .put(
        apiUrl,
        {
          individualPromoId: selectedPromotionObj.individualPromoId,
          newArchived: changedSelected.archived,
        },
        {
          headers: {
            Authorization: `Bearer ${store.getState().user.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        Notification.success(res.data.msg);
        // fetchAllMerch(); use material react table crud feature to add the item to the table in the frontend instead of get it again in the backend
        handleActionMenuClose();
        // handleAccessCardRedirectModalOpen();

        setPromotions([...updatedPromotion, changedSelected]);
      })
      .catch((err: any) => {
        Notification.failed(err.message);
      });
  };

  const columns = useMemo<MRT_ColumnDef<IIndividualPromo>[]>(
    () => [
      {
        accessorKey: "promoCode",
        header: "Code",
        size: 50,
        Cell: ({
          row: {
            original: { promoCode, archived },
          },
        }) => {
          return (
            <Typography
              sx={{
                color: `${archived ? "#b14e46" : "#62fcaf"}!important`,
              }}
            >
              {promoCode}
            </Typography>
          );
        },
      },
      {
        accessorKey: "details",
        header: "Details",
        size: 50,
        Cell: ({
          row: {
            original: { details, archived },
          },
        }) => {
          return (
            <Typography
              sx={{
                color: `${archived ? "#b14e46" : "#62fcaf"}!important`,
              }}
            >
              {details}
            </Typography>
          );
        },
      },
      {
        accessorKey: "promoStartDatetime",
        header: "Start Date",
        size: 100,
        Cell: ({ row }) => {
          return (
            <Typography
              sx={{
                color: `${
                  row.original.archived ? "#b14e46" : "#62fcaf"
                }!important`,
              }}
            >
              {row.original.promoStartDatetime &&
                format(
                  new Date(row.original.promoStartDatetime),
                  "dd/MM/yyyy HH:mm"
                )}
            </Typography>
          );
        },
      },
      {
        accessorKey: "promoEndDatetime",
        header: "End Date",
        size: 100,
        Cell: ({ row }) => {
          return (
            <Typography
              sx={{
                color: `${
                  row.original.archived ? "#b14e46" : "#62fcaf"
                }!important`,
              }}
            >
              {row.original.promoEndDatetime &&
                format(
                  new Date(row.original.promoEndDatetime),
                  "dd/MM/yyyy HH:mm"
                )}
            </Typography>
          );
        },
      },
      {
        accessorKey: "seats",
        header: "Seat",
        size: 50,
        Cell: ({ row }) => {
          return (
            <Typography
              sx={{
                color: `${
                  row.original.archived ? "#b14e46" : "#62fcaf"
                }!important`,
              }}
            >
              {row.original.seats}
            </Typography>
          );
        },
      },
      {
        accessorKey: "hoursToHit",
        header: "Min Hours",
        size: 50,
        Cell: ({ row }) => {
          return (
            <Typography
              sx={{
                color: `${
                  row.original.archived ? "#b14e46" : "#62fcaf"
                }!important`,
              }}
            >
              {row.original.hoursToHit} hrs
            </Typography>
          );
        },
      },
      {
        accessorKey: "maxCapped",
        header: "Max Capped",
        size: 50,
        Cell: ({ row }) => {
          return (
            <Typography
              sx={{
                color: `${
                  row.original.archived ? "#b14e46" : "#62fcaf"
                }!important`,
              }}
            >
              RM {row.original.maxCapped}
            </Typography>
          );
        },
      },
      {
        accessorKey: "isMilestone",
        header: "Milestone",
        size: 50,
        Cell: ({
          row: {
            original: { isMilestone, archived },
          },
        }) => (
          <Checkbox
            checked={isMilestone}
            sx={{
              color: `${archived ? "#b14e46" : "#62fcaf"}!important`,
            }}
          />
        ),
      },
      {
        accessorKey: "stackable",
        header: "Stackable",
        size: 50,
        Cell: ({
          row: {
            original: { stackable, archived },
          },
        }) =>
          stackable ? (
            <LayersIcon
              sx={{
                marginLeft: "20px",
                color: `${archived ? "#b14e46" : "#62fcaf"}!important`,
              }}
            />
          ) : (
            <LayersClearIcon
              sx={{
                marginLeft: "20px",
                color: `${archived ? "#b14e46" : "#62fcaf"}!important`,
              }}
            />
          ),
      },
      {
        header: "Actions",
        size: 50,
        Cell: ({ row }) => (
          <IconButton
            id={row.original.individualPromoId}
            onClick={() => {
              handleActionMenuOpen(row.original.individualPromoId);
              handleSelectedPromotionObjChange(row.original);
            }}
          >
            <SettingsIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
        ),
      },
    ],
    []
  );

  return (
    <Box
      width={"80vw"}
      minWidth={"80em"}
      height={"fit-content"}
      sx={{
        postition: "absolute",
        inset: "0",
        marginX: "auto",
      }}
    >
      <Box width={"100%"} display={"flex"}>
        <Box
          width={"18%"}
          height={"100px"}
          border={`3px solid ${theme.palette.primary.main}`}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <FilterAltOutlinedIcon
            sx={{
              fontSize: "2rem",
              color: "white",
            }}
          />
          <Typography variant={"h3"}>Filter</Typography>
        </Box>
        <Stack width={"41%"} direction={"column"}>
          <Box
            width={"100%"}
            height={"50px"}
            border={`3px solid ${theme.palette.primary.main}`}
            borderLeft={"none"}
            borderBottom={"none"}
            display={"flex"}
            alignItems={"center"}
            padding={2}
          >
            <Typography>Archived</Typography>
          </Box>
          <Box
            width={"100%"}
            height={"50px"}
            border={`3px solid ${theme.palette.primary.main}`}
            borderLeft={"none"}
            display={"flex"}
            alignItems={"center"}
            bgcolor={theme.palette.background.default}
          >
            <Select
              size="small"
              value={excludeArchive}
              onChange={(event) => {
                setExcludeArchive(event.target.value === "true");
                if (event.target.value === "true") {
                  const newData = promotions?.filter(
                    (item) => item.archived === false
                  );
                  handlePromotionChange(newData);
                } else {
                  handlePromotionChange(promotions);
                }
              }}
              sx={{
                width: "100%",
                ".MuiSelect-select": {
                  border: 0,
                  borderColor: theme.palette.primary.main,
                  borderRadius: 0,
                  // borderBottom:0,
                  borderRight: 0,
                  borderLeft: 0,
                },
                ".MuiSelect-icon": {
                  fill: "white",
                },
              }}
            >
              <MenuItem value={"true"}>Exclude</MenuItem>
              <MenuItem value={"false"}>Include</MenuItem>
            </Select>
          </Box>
        </Stack>
        <Stack width={"41%"} direction={"column"}>
          <Box
            width={"100%"}
            height={"50px"}
            border={`3px solid ${theme.palette.primary.main}`}
            borderLeft={"none"}
            borderBottom={"none"}
            display={"flex"}
            alignItems={"center"}
            padding={2}
          >
            <Typography>Milestone</Typography>
          </Box>
          <Box
            width={"100%"}
            height={"50px"}
            border={`3px solid ${theme.palette.primary.main}`}
            borderLeft={"none"}
            display={"flex"}
            alignItems={"center"}
            bgcolor={theme.palette.background.default}
          >
            <Select
              size="small"
              value={milestoneFilter}
              onChange={(event) => {
                setMilestoneFilter(event.target.value);
              }}
              sx={{
                width: "100%",
                ".MuiSelect-select": {
                  border: 0,
                  borderColor: theme.palette.primary.main,
                  borderRadius: 0,
                  // borderBottom:0,
                  borderRight: 0,
                  borderLeft: 0,
                },
                ".MuiSelect-icon": {
                  fill: "white",
                },
              }}
            >
              {milestoneFilters.map((filter) => (
                <MenuItem value={filter}>{filter}</MenuItem>
              ))}
            </Select>
          </Box>
        </Stack>
      </Box>
      <ManagementContentArea<IIndividualPromo>
        title={"Individual Promotion"}
        tableItems={displayPromotions}
        handleAddItemModalOpen={handleAddPromotionModalOpen}
        columns={columns}
        pagination={pagination}
        setPagination={setPagination}
        activeOverview
        searchQuery={searchQuery}
        handleSearchQueryChange={handleSearchQueryChange}
        fetchAllItems={fetchAllPromotions}
        onlyContent={true}
      />
      <ManagementActionMenu
        ref={actionMenuAnchorElRef}
        isActionMenuOpen={isActionMenuOpen}
        handleActionMenuClose={handleActionMenuClose}
        handleEditInfoModalOpen={handleEditPromotionModalOpen}
        handleArchiveMerch={handleArchivePromotion}
        selectedIndividualPromotion={selectedPromotionObj}
      />
      <IndividualPromotionModal
        title={"Add Promotion"}
        type={"add"}
        isPromotionModalOpen={isAddPromotionModalOpen}
        handlePromotionModalClose={handleAddPromotionModalClose}
        fetchAllPromotions={fetchAllPromotions}
      />
      <IndividualPromotionModal
        title={"Edit Promotion"}
        type={"edit"}
        isPromotionModalOpen={isEditPromotionModalOpen}
        handlePromotionModalClose={handleEditPromotionModalClose}
        initialValues={selectedPromotionObj}
        fetchAllPromotions={fetchAllPromotions}
      />
    </Box>
  );
}
